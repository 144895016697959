import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { authApiSlice, useDetailsQuery, useEnrollmentsQuery } from '../../api/authApiSlice';
import { IEnrollment, IStudent, IUser } from '../../interface/user';
import { useAppSelector } from '../../reducers/hooks';
import ContactCard from '../utils/ContactCard';
import AccountDetails from './AccountDetails';
import EnrollmentDetails from './EnrollmentDetails';
import StudentDetails from './StudentDetails';

interface props {
    tab?: string
}

const Account = (props: props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const languageCode = i18next.language;
    const auth = useAppSelector(state => state.auth);

    const [sideBarLocation, updateSideBarLocation] = useState("");
    var userData: IUser | null = null
    var userEnrollments: IEnrollment[] | undefined = undefined

    const userDetailsRequest = useDetailsQuery({})
    if (!userDetailsRequest.error && !userDetailsRequest.isLoading) {
        userData = userDetailsRequest.data ? userDetailsRequest.data["data"] as IUser : null
    }

    const userEnrollmentsRequest = useEnrollmentsQuery({})
    if (!userEnrollmentsRequest.error && !userEnrollmentsRequest.isLoading) {
        userEnrollments = userEnrollmentsRequest.data["data"] as IEnrollment[]
    }

    function changeTab(newTab: string) {
        updateSideBarLocation(newTab)
    }

    useEffect(() => {
        updateSideBarLocation(props.tab ? props.tab : "ACCOUNT")
    }, [props])


    return (
        <section
            className="bg-light d-flex flex-nowrap"
            style={{
                height: "100vh"
            }}
        >
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <div className="col-2 col-md-4 col-xl-3 px-sm-2 px-0 bg-dark d-flex justify-content-center justify-content-md-start">
                        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 flex-md-grow-1">
                            <div className="d-none d-md-flex align-items-center justify-content-center w-100 pb-3 mb-md-0 me-md-auto text-white text-decoration-none border-bottom-0 border-md-bottom">
                                <span className="fs-4 d-none d-md-inline mt-3 text-center">{auth.name ? t("greeting", {ns: "course", name: auth.name }) : ""}</span>
                            </div>
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                                {/* Account Details */}
                                <li className="nav-item mt-2 mb-1">
                                    <a className="nav-link align-middle px-0 d-flex justify-content-end align-items-center" onClick={() => { navigate("/" + languageCode + "/account/details") }}>
                                        <i className="fs-2 bi-person-circle pr-2 d-none d-md-block"></i>
                                        <i className="fs-1 bi-person-circle pr-2 d-block d-md-none"></i>
                                        <span className="ms-1 fs-4 d-none d-md-block px-2">{t("account", {ns: "course"})}</span>
                                    </a>
                                </li>

                                {/* Enrollment Details */}
                                <li className="nav-item">
                                    <a className="nav-link align-middle px-0 d-flex justify-content-end align-items-center" onClick={() => { navigate("/" + languageCode + "/account/enrollments") }}>
                                        <i className="fs-2 bi-calendar3 pr-2 d-none d-md-block"></i>
                                        <i className="fs-1 bi-calendar3 pr-2 d-block d-md-none"></i>
                                        <div className="ms-1 fs-4 d-none d-md-block px-2">{t("enrollments", {ns: "course"})}</div>
                                    </a>
                                </li>

                                {/*
                                <li>
                                    <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle ">
                                        <i className="fs-4 bi-bootstrap"></i> <span className="ms-1 d-none d-sm-inline">Bootstrap</span></a>
                                    <ul className="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                                        <li className="w-100">
                                            <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 1</a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-0"> <span className="d-none d-sm-inline">Item</span> 2</a>
                                        </li>
                                    </ul>
                                </li>
                                */}

                            </ul>
                            <hr />
                        </div>
                    </div>
                    <div className="col py-3">
                        {sideBarLocation === "ACCOUNT" &&
                            <>
                                <AccountDetails user={userData ? userData : undefined} />
                                <StudentDetails students={userData?.students} />
                                {!userDetailsRequest.isLoading &&
                                    <div className="container fadeIn">
                                        <div className="my-3 p-3 bg-body rounded shadow-sm d-flex justify-content-center">
                                            <div className="col col-md-10">
                                                <ContactCard prompt={t("update_details", {ns: "course"})} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {sideBarLocation === "ENROLLMENTS" &&
                            <>
                                <EnrollmentDetails enrollments={userEnrollments} />
                                {!userDetailsRequest.isLoading &&
                                    <div className="container fadeIn">
                                        <div className="my-3 p-3 bg-body rounded shadow-sm d-flex justify-content-center">
                                            <div className="col col-md-10">
                                                <ContactCard prompt={t("questions_enrollment", {ns: "course"})} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Account;