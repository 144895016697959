import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ContactUsButton from '../utils/ContactUsButton';

const DefaultFooter = () => {
    const { t } = useTranslation();
    const languageCode = i18next.language;

    return (
        <footer className="py-3 my-4">
            <ul className="nav pb-3 justify-content-center">
                <li className="nav-item">
                    <NavLink className="nav-link px-2 text-muted" to={"/" + languageCode + "/"}>
                        {t('home')}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link px-2 text-muted" to={"/" + languageCode + "/faq"}>
                        {t('faqs')}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link px-2 text-muted" to={"/" + languageCode + "/about"}>
                        {t('about_us')}
                    </NavLink>
                </li>
            </ul>
            <div className="nav border-bottom
                            pb-3 mb-1 row justify-content-center">
                <div className="col-md-4 col-8">
                    <ContactUsButton />
                </div>
            </div>
            <p className="text-center text-muted">&copy; 2022 Jack Stubbs</p>

        </footer>
    )
}

export default DefaultFooter;