import { IEnrollmentsRequest } from "../interface/apiRequests";
import { IEnrollmentRequest } from "../interface/course";
import { apiSlice, PROTECTED_ROUTES } from "./apiSlice";

const BASE_URL_OFFERING = "/offering/"
const BASE_URL_COURSE = "/course/"

export const courseApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        offering: builder.query({
            query: (offering: number) => ({
                url: BASE_URL_OFFERING + offering,
                method: 'GET'
            })
        }),
        courses: builder.query({
            query: () => ({
                url: BASE_URL_COURSE + 'all',
                method: 'GET'
            }),
        }),
        enroll: builder.mutation({
            query: (request: IEnrollmentsRequest) => ({
                url: PROTECTED_ROUTES + BASE_URL_OFFERING + 'enroll',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Students', 'Enrollments']
        }),
    }),
    overrideExisting: false,
})

export const {
    useOfferingQuery, useCoursesQuery, useEnrollMutation
} = courseApiSlice