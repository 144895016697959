import Modal from "bootstrap/js/dist/modal";
import i18next from "i18next";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { useLoginMutation, useResetPasswordMutation, useSignupMutation } from "../../api/authApiSlice";
import { ILoginRequest, IPasswordResetRequest, ISignupRequest } from "../../interface/apiRequests";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../reducers/hooks";

interface LoginProps {
    view: string,
    fadeBackground: boolean
}

const Authenticate = (props: LoginProps) => {
    const dispatch = useAppDispatch()

    const [form, updateForm] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: "",
        attempted: false
    });
    const [showState, updateShowState] = useState(props.view);
    const [modalOpen, updateModalState] = useState(false);
    const [modalDesiredState, updateModalDesiredState] = useState<Boolean | null>(false);
    const [credentials, updateCredentials] = useState(undefined);
    const [modalState, changeModalState] = useState("LOADING");
    const [errorDescription, updateErrorDescription] = useState("");
    const languageCode = i18next.language;
    var { t } = useTranslation();

    const [signup] = useSignupMutation();
    const [login] = useLoginMutation();
    const [reset] = useResetPasswordMutation();

    const [modal, updateModal] = useState<Modal | null>(null);

    useEffect(() => {
        var myModalEl = document.getElementById('actionModal')!;
        var modal = Modal.getOrCreateInstance(myModalEl);
        myModalEl.addEventListener('hidden.bs.modal', async function (event) {
            updateModalState(false);
            if (credentials) {
                //console.log("A")
                await dispatch(setCredentials({ ...(credentials as {}) }))
                updateCredentials(undefined)
            }
        });
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            updateModalState(true);
        });
        updateModal(modal);
    }, []);

    useEffect(() => {
        if (!modalOpen && credentials) {
            dispatch(setCredentials({ ...(credentials as {}) }))
            updateCredentials(undefined)
        }
    }, [modalOpen])


    useEffect(() => {
        if (modalDesiredState === true && modalOpen === false) {
            modal!.show();
            updateModalDesiredState(null)
        } else if (modalDesiredState === false && modalOpen === true) {
            modal!.hide();
            updateModalDesiredState(null)
        }
    }, [modalOpen, modalDesiredState]);

    function showModal() {
        updateModalDesiredState(true)
    }

    function closeModal() {
        updateModalDesiredState(false)
    }

    function resetForm() {
        updateForm({
            name: "",
            email: "",
            phone: "",
            password: "",
            passwordConfirm: "",
            attempted: false
        })
    }

    function switchView(location: string) {
        updateShowState(location);
        resetForm()
    }

    function updateState(key: string, value: any) {
        updateForm((prevState) => {
            return { ...prevState, [key]: value }
        });
    }

    function isValid(data: String, type: String = "", override: Boolean = false, isLogin: Boolean = false) {
        let flag = false;
        let value = String(data).trim();

        if (!form.attempted && !override)
            return "";

        switch (type) {
            case "email":
                flag = validator.isEmail(value);
                break;
            case "phone":
                flag = validator.isMobilePhone(value) && value.length > 5;
                break;
            case "password":
                flag = validator.isStrongPassword(value, { minLength: 10, minLowercase: 0, minUppercase: 0, minNumbers: 1, minSymbols: 0, returnScore: false, })
                break;
            case "passwordConfirm":
                if (!isValid(form.password, "password", true)) { return ""; }
                flag = form.password === form.passwordConfirm;
                break;
            default:
                flag = value !== "";
        }

        if (override)
            return flag;
        if (!isLogin)
            return flag ? "is-valid" : "is-invalid";
        return flag ? "" : "is-invalid";
    }

    function signupValid() {
        return isValid(form.name, "name", true) &&
            isValid(form.email, "email", true) &&
            isValid(form.phone, "phone", true) &&
            isValid(form.password, "password", true) &&
            isValid(form.passwordConfirm, "passwordConfirm", true);
    }

    function loginValid() {
        return isValid(form.email, "email", true) &&
            isValid(form.password, "", true)
    }

    function resetPasswordValid() {
        return isValid(form.email, "email", true)
    }

    const handleSignup = async (e: any) => {
        e.preventDefault()
        updateState("attempted", true)

        if (!signupValid())
            return;

        showModal();
        changeModalState("LOADING");

        try {
            const signupRequest: ISignupRequest = {
                email: form.email,
                pwd: form.password,
                name: form.name,
                phone: form.phone
            }
            const userData = await signup(signupRequest).unwrap()
            const loginSuccessful = await makeLoginRequest(form.email, form.password);
            if (loginSuccessful) {
                closeModal();
            }
        } catch (err: any) {
            if (err?.data !== undefined) {
                updateErrorDescription(t(err.data.error, { ns: "error" }))
            } else {
                updateErrorDescription(t("error_login_failed", { ns: "error" }))
            }

            changeModalState("ERROR");
        }
    }

    const handleLogin = async (e: any) => {
        e.preventDefault()
        updateState("attempted", true)

        if (!loginValid())
            return;

        showModal();
        makeLoginRequest(form.email, form.password)
    }

    const handleReset = async (e: any) => {
        e.preventDefault()
        updateState("attempted", true)

        if (!resetPasswordValid())
            return;

        showModal();
        const result = await makeResetPasswordRequest(form.email)
        if (result) {
            changeModalState("SUCCESS_PASSWORD");
            resetForm()
        } else {
            changeModalState("ERROR");
            updateErrorDescription(t("error_generic", { ns: "error" }))
        }
    }

    async function makeResetPasswordRequest(email: string) {
        try {
            const passwordRequest: IPasswordResetRequest = {
                email: email,
                lng: languageCode
            }
            const userData = await reset(passwordRequest).unwrap();
            if (userData["data"]) {
                return true
            }

        } catch (err: any) {

            if (err?.data !== undefined) {
                updateErrorDescription(t(err.data.error, { ns: "error" }))
            } else {
                updateErrorDescription(t("error_login_failed", { ns: "error" }))
            }

            changeModalState("ERROR");
            return false;
        }

        return true;
    }

    async function makeLoginRequest(email: string, password: string) {
        try {
            const loginRequest: ILoginRequest = {
                email: email,
                pwd: password
            }
            const userData = await login(loginRequest).unwrap();
            await updateCredentials(userData)
            closeModal();
        } catch (err: any) {

            if (err?.data !== undefined) {
                updateErrorDescription(t(err.data.error, { ns: "error" }))
            } else {
                updateErrorDescription(t("error_login_failed", { ns: "error" }))
            }

            changeModalState("ERROR");
            return false;
        }

        return true;
    }

    return (
        <>
            {/* Sign Up */}
            <div className={"row fadeIn " + (props.fadeBackground ? "rounded rounded-5 py-3 px-2" : "")} hidden={showState != "SIGNUP"}
                style={props.fadeBackground ? {
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)",
                } : {}}>
                <div className="container col-lg-7 px-4">
                    <div className="card-title h4 text-center mb-3  pb-2 border-dark border border-1 border-top-0 border-left-0 border-right-0">
                        {t("create_an_account", { ns: "validate" })}
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-person h5 align-self-center my-1" /></span>
                        <input type="text" className={"form-control " + isValid(form.name)} placeholder={t("full_name", { ns: "validate" })} aria-label="fullname" aria-describedby="Full Name" onChange={(e: any) => { updateState("name", e.target.value) }} />
                        <div id="validateSignupName" className="invalid-feedback">
                            {t("validate_name", { ns: "validate" })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope h5 align-self-center my-1" /></span>
                        <input type="email" className={"form-control " + isValid(form.email, "email")} placeholder={t("email", { ns: "validate" })} aria-label="email" aria-describedby="Email" onChange={(e: any) => { updateState("email", e.target.value) }} />
                        <div id="validateEmail" className="invalid-feedback">
                            {t("validate_email", { ns: "validate" })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-phone h5 align-self-center my-1" /></span>
                        <input type="phone" className={"form-control " + isValid(form.phone, "phone")} placeholder={t("phone", { ns: "validate" })} aria-label="email" aria-describedby="Email" onChange={(e: any) => { updateState("phone", e.target.value) }} />
                        <div id="validatePhone" className="invalid-feedback">
                            {t("validate_phone", { ns: "validate" })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-lock h5 align-self-center my-1" /></span>
                        <input type="password" className={"form-control " + isValid(form.password, "password")} placeholder={t("password", { ns: "validate" })} aria-label="password" aria-describedby="Password" onChange={(e: any) => { updateState("password", e.target.value) }} />
                        <div id="validateSignupPassword" className="invalid-feedback">
                            {t("validate_password_insuf", { ns: "validate" })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-lock h5 align-self-center my-1" /></span>
                        <input type="password" className={"form-control " + isValid(form.passwordConfirm, "passwordConfirm")} placeholder={t("password_again", { ns: "validate" })} aria-label="passwordVerification" aria-describedby="PasswordVerification" onChange={(e: any) => { updateState("passwordConfirm", e.target.value) }} />
                        <div id="validateSignupPassword2" className="invalid-feedback">
                            {t("validate_password_diff", { ns: "validate" })}
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-dark w-100" onClick={handleSignup}
                    >
                        {t("sign_up", { ns: "validate" })}
                    </button>
                    <div className="d-flex align-content-center justify-content-center mt-2">
                        <div className="text-faded align-self-center mx-1">{t("or", { ns: "validate" })}</div>
                        <button type="button" className="btn btn-link align-self-center mx-1" onClick={() => { switchView("LOGIN") }}>
                            {t("login", { ns: "validate" })}
                        </button>
                    </div>

                </div>
            </div>

            {/* Login */}
            <div className={"row fadeIn " + (props.fadeBackground ? "rounded rounded-5 py-3 px-2" : "")} hidden={showState != "LOGIN"}
                style={props.fadeBackground ? {
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)"
                } : {}}>
                <div className="container col-lg-7 px-4">
                    <div className="card-title h4 text-center mb-3 pb-2 border-dark border border-1 border-top-0 border-left-0 border-right-0">
                        {t("login_to_your_account", { ns: "validate" })}
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope h5 align-self-center my-1" /></span>
                        <input type="email" className={"form-control " + isValid(form.email, "email", false, true)} placeholder={t("email", { ns: "validate" })} aria-label="email" aria-describedby="Email" onChange={(e: any) => { updateState("email", e.target.value) }} />
                        <div id="validateLoginEmail" className="invalid-feedback">
                            {t("validate_email", { ns: "validate" })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-lock h5 align-self-center my-1" /></span>
                        <input type="password" className={"form-control " + isValid(form.password, "", false, true)} placeholder={t("password", { ns: "validate" })} aria-label="password" aria-describedby="Password" onChange={(e: any) => { updateState("password", e.target.value) }} />
                        <div id="validateLoginPassword" className="invalid-feedback">
                            {t("validate_password", { ns: "validate" })}
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-dark w-100" onClick={handleLogin}>
                        {t("login", { ns: "validate" })}
                    </button>
                    <div className="d-flex align-content-center justify-content-center mt-2">
                        <button type="button" className="btn btn-link align-self-center mx-1"
                            onClick={() => { switchView("SIGNUP") }}>
                            {t("sign_up_link", { ns: "validate" })}
                        </button>
                        <div className="text-faded align-self-center mx-1">{t("or", { ns: "validate" })}</div>
                        <button type="button" className="btn btn-link align-self-center mx-1"
                            onClick={() => { switchView("FORGOT") }}>
                            {t("forgot_password_link", { ns: "validate" })}
                        </button>
                    </div>

                </div>
            </div>

            {/* Reset Password */}
            <div className={"row fadeIn " + (props.fadeBackground ? "rounded rounded-5 py-3 px-2" : "")} hidden={showState != "FORGOT"}
                style={props.fadeBackground ? {
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)"
                } : {}}>
                <div className="container col-lg-7 px-4">
                    <div className="card-title h4 text-center mb-3 pb-2 border-dark border border-1 border-top-0 border-left-0 border-right-0">
                        {t("password_reset", { ns: "validate" })}
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope h5 align-self-center my-1" /></span>
                        <input id="rest_password_value" type="email" className={"form-control " + isValid(form.email, "email", false, true)} value={form.email} placeholder={t("email", { ns: "validate" })} aria-label="email" aria-describedby="Email" onChange={(e: any) => { updateState("email", e.target.value) }} />
                        <div id="validateLoginEmail" className="invalid-feedback">
                            {t("validate_email", { ns: "validate" })}
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-dark w-100" onClick={handleReset}>
                    {t("send_password_reset_link", { ns: "validate" })}
                    </button>
                    <div className="d-flex align-content-center justify-content-center mt-2">
                        <button type="button" className="btn btn-link align-self-center mx-1"
                            onClick={() => { switchView("LOGIN") }}>
                            {t("login", { ns: "validate" })}
                        </button>
                        <div className="text-faded align-self-center mx-1">{t("or", { ns: "validate" })}</div>
                        <button type="button" className="btn btn-link align-self-center mx-1"
                            onClick={() => { switchView("SIGNUP") }}>
                            {t("sign_up_link", { ns: "validate" })}
                        </button>
                    </div>

                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="actionModal" tabIndex={-1} aria-labelledby="actionModalLabel" aria-hidden="true">
                {/** Contact Us Modal (Submitted) */}
                {modalState === "LOADING" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t("sending_request", { ns: "validate" })}</h5>
                            <button type="button" id="modalClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeModal() }}></button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="spinner-border text-primary m-4" role="status">
                                <span className="visually-hidden">{t("loading", { ns: "validate" })}</span>
                            </div>
                        </div>
                    </div>
                </div>}

                {/** Contact Us Modal (Submitted) */}
                {modalState === "SUCCESS" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t("account_created", { ns: "validate" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeModal() }}></button>
                        </div>
                        <div className="modal-body">
                            <h1 className='text-center'>🥳</h1>
                            <p className='text-center h6'>{t("account_created_message", { ns: "validate" })}</p>
                        </div>
                        <div className="container mb-3">
                            <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal" onClick={() => { closeModal() }}>{t("close", { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}

                {/** Contact Us Modal (Submitted) */}
                {modalState === "SUCCESS_PASSWORD" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t("reset_password_processed", { ns: "validate" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeModal() }}></button>
                        </div>
                        <div className="modal-body">
                            <p className='text-center h6'>{t("reset_password_processed_message", { ns: "validate" })}</p>
                        </div>
                        <div className="container mb-3">
                            <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal" onClick={() => { closeModal() }}>{t("close", { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}

                {/** Error */}
                {modalState === "ERROR" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t("whoops", { ns: "validate" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeModal() }}></button>
                        </div>
                        <div className="modal-body">
                            <h1 className='text-center'>❌</h1>
                            <p className='text-center h6'>{errorDescription} </p>
                        </div>
                        <div className="container mb-3">
                            <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal" onClick={() => { closeModal() }}>{t("close", { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default Authenticate;
