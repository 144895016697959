import { useTranslation } from "react-i18next";
import { useInstructorsQuery } from "../../api/instructorApiSlice";
import DefaultFooter from "../footer/DefaultFooter";


const FAQ = () => {

    var { t } = useTranslation();
    var FAQ_QUESTION_COUNT = 6

    return (
        <>
            <div className="bg-light min-vh-100">
                <section className="p-3 container">
                    {/* Course List Title */}
                    <div className="d-flex align-items-center justify-content-between 
                                p-3 my-3 text-muted border-secondary shadow-sm rounded-2"
                        style={{
                            backgroundColor: "var(--bs-purple)",
                        }}>
                        <i className="bi bi-question-circle h1 text-white d-none d-md-block"></i>
                        <h1 className="h3 mb-0 text-light text-center w-100 lh-1">{t('header_faq')}</h1>
                        <i className="bi bi-question-circle h1 text-white d-none d-md-block"></i>
                    </div>
                    <div className="bg-light d-flex align-items-start justify-content-center" style={{ height: "55vh" }}>

                        <div className="w-100">
                            <div className="accordion accordion-flush" id="questions">
                                {[...Array(FAQ_QUESTION_COUNT)].map((x, i) =>
                                    <div className="accordion-item" key={i}>
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed "
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={"#question-" + i}
                                                /*style={{
                                                    backgroundColor: "#e9ecef"
                                                }}*/
                                            >
                                                {t('question_title_' + i, { ns: "faq" })}
                                            </button>
                                        </h2>
                                        <div
                                            id={"question-" + i}
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#questions"
                                            style={{
                                                backgroundColor: "#f8f9fa"
                                            }}
                                        >
                                            <div className="accordion-body">
                                                {t('question_answer_' + i, { ns: "faq" })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </section >
                <DefaultFooter />
            </div >
        </>

    )
}

export default FAQ;