import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';
import { IEnolledStudent } from '../../interface/course';
import { IStudent, IUser } from '../../interface/user';
import { addStudent, updateStudent } from '../../reducers/courseSlice';

// Constants which can be used to fire events on the Modal
const MODAL_DATA_BS_TOGGLE: string = "modal";
const MODAL_DATA_BS_TARGET: string = "#addStudentModal";

interface props {
    studentIndex: number,
    userDetails?: IUser
}

const StudentDetailsModal = (props: props) => {
    let DEFAULT_STUDENT: IEnolledStudent = {
        is_account: false,
        name: "",
        birth_year: 2010,
        current_grade: ""
    };

    const dispatch = useDispatch();
    const {t} = useTranslation()
    const students: IEnolledStudent[] = useSelector((state: RootState) => state.course.students);

    let [student, changeStudent] = useState(props.studentIndex === -1 ? DEFAULT_STUDENT : students[props.studentIndex]);
    let [formComplete, changeFormComplete] = useState(false);
    let [attempted, changeAttempted] = useState(false);
    let [chosenStudent, changeChosenStudent] = useState(false);
    let [isEdting, changeIsEditing] = useState(props.studentIndex !== -1);

    function nameInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        changeStudent((prevState) => {
            return { ...prevState, name: event.target.value }
        });
    }

    function yearInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        changeStudent((prevState) => {
            return { ...prevState, birth_year: Number(event.target.value) }
        });
    }

    function gradeInputHandler(event: any) {
        changeStudent((prevState) => {
            return { ...prevState, current_grade: event.target.value }
        });
    }

    function resetForm() {
        changeStudent(props.studentIndex === -1 ? DEFAULT_STUDENT : students[props.studentIndex]);
        changeAttempted(false);
        changeChosenStudent(false);
    }

    function isValid(data: String | Number, type: String = "", override: Boolean = false) {
        let flag = false;
        let value = String(data).trim();

        if (!attempted && !override) {
            return "";
        }

        switch (type) {
            case "year":
                // TODO: Remove magic numbers
                flag = data > 1970 && data < 2022;
                break;
            case "grade":
                flag = value !== "";
                break;
            default:
                flag = value !== "";
        }

        if (override)
            return flag;
        return flag ? "is-valid" : "is-invalid";
    }

    function formIsValid(): Boolean {
        if (!student.birth_year || !student.current_grade) {
            return false;
        }
        const nameCorrect = isValid(student.name, "", true);
        const yearCorrect = isValid(student.birth_year, "year", true);
        const gradeCorrect = isValid(student.current_grade, "grade", true);

        return (nameCorrect === true && yearCorrect === true && gradeCorrect === true);
    }

    async function submitForm(event: React.MouseEvent<HTMLButtonElement>) {
        if (!formIsValid()) {
            changeAttempted(true);
        } else {
            if (isEdting) {
                dispatch(updateStudent({ id: props.studentIndex, student: student }));
            } else {
                dispatch(addStudent(student));
            }
            resetForm();
        }
    }

    async function addExistingStudent(student: IStudent) {
        await dispatch(addStudent({
            name: student.name,
            is_account: student.is_account,
            birth_year: (new Date(String(student.birth_year))).getFullYear() + 1,
            current_grade: student.grade,
            uuid: student.uuid
        } as IEnolledStudent));
    }

    useEffect(() => {
        changeFormComplete(formIsValid() ? true : false);
    }, [student, formIsValid]);

    useEffect(() => {
        changeStudent(props.studentIndex === -1 ? DEFAULT_STUDENT : students[props.studentIndex]);
        changeIsEditing(props.studentIndex !== -1);
    }, [props.studentIndex]);

    useEffect(() => {
        if (props.studentIndex !== -1) {
            changeStudent(students[props.studentIndex]);
        }
    }, [students]);

    return (
        <div className="modal fade" id="addStudentModal" tabIndex={-1} aria-labelledby="addStudentModalLabel" aria-hidden="true">
            {/* Contact Us Modal (Input) */}
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    {/* Choose existing Student Modal */}
                    {!isEdting && !chosenStudent && props.userDetails && props.userDetails.students.filter(student => students.find(findStudent => findStudent.uuid == student.uuid) == undefined && !student.is_account).length > 0 && <>
                        <div className="modal-header">
                            <h4 className="modal-title">{t('choose_add_student', {ns: "enrollment"})}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                        </div>
                        <div className="modal-body d-flex flex-column align-items-start">
                            <p className='text-align-center w-100'>
                            {t('choose_add_student_desc', {ns: "enrollment"})}
                            </p>
                            {props.userDetails.students.filter(student => students.find(findStudent => findStudent.uuid == student.uuid) == undefined && !student.is_account).map((student, index) =>
                                <button type="button" className="btn btn-secondary w-100 mt-2 py-2"
                                    key={index}
                                    data-bs-dismiss="modal"
                                    onClick={() => { addExistingStudent(student) }}
                                >
                                    {`${student.name} (${(new Date(String(student.birth_year))).getFullYear() + 1})`}
                                </button>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary col"
                                data-bs-dismiss={formComplete && "modal"}
                                onClick={() => { changeChosenStudent(true) }} >
                                {"Add a New Student"}
                            </button>
                        </div>
                    </>}

                    {/* Create/Existing Student */}
                    {!(!isEdting && !chosenStudent && props.userDetails && props.userDetails.students.filter(student => students.find(findStudent => findStudent.uuid == student.uuid) == undefined && !student.is_account).length > 0) && <>
                        <div className="modal-header">
                            <h4 className="modal-title">{t("add_new_student", {ns: "enrollment"})}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="mb-3 col-6 d-flex flex-column align-items-start">
                                    <label htmlFor="validateName" className="form-label">{t("name", {ns: "validate"})}</label>
                                    <input type="text" className={"form-control " + isValid(student.name)} id="validateName"
                                        onChange={nameInputHandler} value={student.name} />
                                    <div id="validateName" className="invalid-feedback">
                                    {t("name_student", {ns: "validate"})}
                                    </div>
                                </div>

                                <div className="mb-3 col-6 d-flex flex-column align-items-start">
                                    <label htmlFor="validateYear" className="form-label">{t("birth_year", {ns: "validate"})}</label>
                                    <input type="number" className={"form-control " + isValid(student.birth_year ? student.birth_year : -1, "year")} id="validateYear"
                                        onChange={yearInputHandler} value={student.birth_year} placeholder="2010" />
                                    <div id="validateYear" className="invalid-feedback">
                                    {t("validate_year", {ns: "validate"})}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3 col d-flex flex-column align-items-start">
                                <label htmlFor="validateGrade" className="form-label">{t("current_grade", {ns: "validate"})}</label>
                                <select className={"form-select form-control " + isValid(student.current_grade ? student.current_grade : "", "grade")} id="validateGrade"
                                    onChange={gradeInputHandler} value={student.current_grade}>
                                    <option value="">{t("select_grade", {ns: "validate"})}</option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((grade, index) =>
                                        <option key={index} value={t("grade_" + grade, {ns: "validate"})}>{t("grade_" + grade, {ns: "validate"})}</option>
                                    )}
                                </select>
                                <div id="validateGrade" className="invalid-feedback flex-fill text-center">
                                {t("validate_grade", {ns: "validate"})}
                                </div>
                            </div>

                            <div className="modal-footer row">
                                <button type="button" className="btn btn-secondary col"
                                    data-bs-dismiss="modal" onClick={resetForm}>{t("cancel", {ns: "validate"})}</button>
                                <button type="button" className="btn btn-primary col-8"
                                    data-bs-dismiss={formComplete && "modal"}
                                    onClick={submitForm} >
                                    {isEdting ? t("update_details", {ns: "enrollment"}) : t("add_student", {ns: "enrollment"})}
                                </button>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>

    );

}

export default StudentDetailsModal;

export const StudentDetailModalHooks = { MODAL_DATA_BS_TARGET, MODAL_DATA_BS_TOGGLE };