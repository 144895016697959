import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IContactSubmission } from '../interface/models';
import { ILoginRequest, ISignupRequest } from "../interface/apiRequests";
import { apiSlice } from "./apiSlice";

const BASE_URL = "/contact/"

export const contactApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPosts: builder.query({
            query: () => BASE_URL + 'messages'
        }),

        postContactMessage: builder.mutation({
            query: (message: IContactSubmission) => ({
                url: "sendContactMessage",
                method: "POST",
                body: message 
            })
        })
    }),
    overrideExisting: false,
});

export const {
    useGetPostsQuery,
    usePostContactMessageMutation
} = contactApiSlice;