import { Modal } from 'bootstrap';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../../api/authApiSlice';
import { IPasswordResetRequest } from '../../interface/apiRequests';
import { IUser } from '../../interface/user';

interface props {
    user?: IUser
}

const AccountDetails = (props: props) => {
    const { t } = useTranslation();
    const [nsIsLoading, updateNSIsLoading] = useState(true)
    const [isLoading, updateIsLoading] = useState(true)
    let [completionModalState, updateCompletionModalState] = useState("LOADING");
    const [reset] = useResetPasswordMutation(); 
    const [errorDescription, updateErrorDescription] = useState("");
    const [modal, updateModal] = useState<Modal | null>(null);
    const languageCode = i18next.language;

    useEffect(() => {
        var myModalEl = document.getElementById('resetPasswordModal')!;
        var modal = Modal.getOrCreateInstance(myModalEl);
        updateModal(modal);
    }, []);

    useEffect(() => {
        i18next.loadNamespaces('account_details').then(() => { updateNSIsLoading(false) });
    })

    useEffect(() => {
        if (props.user && !nsIsLoading) {
            updateIsLoading(false)
        } else {
            updateIsLoading(true)
        }
    }, [props, nsIsLoading])

    async function resetPassword() {
        modal?.show()
        updateCompletionModalState("LOADING")
        const request = {
            email: props.user?.email,
            lng: languageCode
        } as IPasswordResetRequest

        try {
            const result = await reset(request).unwrap();
            if ("data" in result && result["data"] === true) {
                updateCompletionModalState("SUCCESS")
            } else {
                updateCompletionModalState("ERROR")
                updateErrorDescription(t(result["error"], { ns: "error" }))
            }
        } catch (error) {
            updateCompletionModalState("ERROR")
            updateErrorDescription(t('generic_error', { ns: "error" }))
        }
    }

    return (
        <>
        {!isLoading && <div className="container fadeIn">
            <div className="my-3 p-3 bg-body rounded shadow-sm">
                <p className="h4 border-bottom pb-2 mb-0">
                    {t('title', { ns: "account_details" })}
                </p>
                <div className="g-3 pt-3 text-break">
                    <ul className="list-inline lead">
                        <li className="list-inline-item col-4 col-md-3 col-lg-2"><strong>{t('name', { ns: "account_details" })}:</strong></li>
                        <li className="list-inline-item col-7 col-md-6 col-lg-4">{props.user?.name}</li>
                    </ul>
                    <ul className="list-inline lead">
                        <li className="list-inline-item col-4 col-md-3 col-lg-2"><strong>{t('email', { ns: "account_details" })}:</strong></li>
                        <li className="list-inline-item col-7 col-md-6 col-lg-4">{props.user?.email}</li>
                    </ul>
                    <ul className="list-inline lead">
                        <li className="list-inline-item col-4 col-md-3 col-lg-2"><strong>{t('phone', { ns: "account_details" })}:</strong></li>
                        <li className="list-inline-item col-7 col-md-6 col-lg-4">{props.user?.phone}</li>
                    </ul>
                </div>
                <button type="button" className={"btn btn-primary btn-lg col-12 col-md-9 col-lg-6 "}
                    onClick={() => {resetPassword()}}>
                    {t('reset_password', { ns: "account_details" })}
                </button>
            </div>
        </div>}

        {isLoading && <div className="container fadeIn">
            <div className="my-3 p-3 bg-body rounded shadow-sm placeholder-glow">
                <span className="h4 col-6 border-bottom pb-2 mb-0 placeholder">
                    
                </span>
                <div className=" g-3 pt-3">
                    <ul className="list-inline lead">
                        <li className="list-inline-item col-4 col-md-3 col-lg-2 placeholder"></li>
                        <li className="list-inline-item col-7 col-md-6 col-lg-4 placeholder"></li>
                    </ul>
                    <ul className="list-inline lead">
                        <li className="list-inline-item col-4 col-md-3 col-lg-2 placeholder"></li>
                        <li className="list-inline-item col-7 col-md-6 col-lg-4 placeholder"></li>
                    </ul>
                    <ul className="list-inline lead">
                        <li className="list-inline-item col-4 col-md-3 col-lg-2 placeholder"></li>
                        <li className="list-inline-item col-7 col-md-6 col-lg-4 placeholder"></li>
                    </ul>
                </div>
                <button type="button" className={"btn btn-primary btn-lg col-12 col-md-9 col-lg-6 disabled placeholder"}>
                    
                </button>
            </div>
        </div>}

        {/* Reset Password Modal */}
        <div className="modal fade" id="resetPasswordModal" data-bs-backdrop="static" data-bs-keyboard={false} tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                {/** Loading */}
                {completionModalState === "LOADING" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t('resetting_password', { ns: "validate" })}</h5>
                        </div>
                        <div className="modal-body text-center">
                            <div className="spinner-border text-primary m-4" role="status">
                                <span className="visually-hidden">{t('loading', { ns: "validate" })}</span>
                            </div>
                        </div>
                    </div>
                </div>}

                {/** Success */}
                {completionModalState == "SUCCESS" && <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('request_sent', { ns: "validate" })}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="text-center fs-5">{t('password_request_sent', { ns: "validate" })}</div>
                            <div className="card-text text-center my-2">{t('password_request_sent_message', { ns: "validate", email: props.user?.email })}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w-100" data-bs-dismiss="modal">{t('got_it', { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}

                {/** Error */}
                {completionModalState === "ERROR" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t('whoops', { ns: "validate" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h1 className='text-center'>❌</h1>
                            <p className='text-center h6'>{errorDescription} </p>
                        </div>
                        <div className="container mb-3">
                            <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal">{t('close', { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default AccountDetails;