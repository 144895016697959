import { Outlet, Link, useLocation, useNavigate } from "react-router-dom"
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../../reducers/authSlice"
import { useRefreshMutation } from "../../api/authApiSlice"
import DefaultFooter from "../footer/DefaultFooter"
import Authenticate from "../authentication/Authenticate"
import { RootState } from "../.."
import i18next from "i18next"
import PasswordReset from "../authentication/PasswordReset"
import { useAppSelector } from "../../reducers/hooks"

interface Props {
    view: string,
    key: number
}

const LoginSignup = (props: Props) => {

    const auth = useAppSelector(state => state.auth);
    const location = useLocation();
    const languageCode = i18next.language;
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.token && props.view != "RESET") {
            if (props.view == "SIGNUP") {
                navigate("/" + languageCode + "/courses")
            } else {
                navigate("/" + languageCode + "/account/details")
            }
        }
    }, [auth])

    useEffect(() => {

    }, [props])

    return (
        <>
            <section
                className="text-center text-sm-start bg-image"
                style={{
                    backgroundImage: "url(/img/student_learning.jpg)",
                    backgroundSize: "cover",
                    maxWidth: "100%",
                    minHeight: "70vh",
                    height: "auto",
                    position: "relative"
                }}
            >
                <div className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                        width: "100%",
                        minHeight: "70vh",
                    }}>
                    <div className="col-10 col-md-6 rounded rounded-5 p-3">
                        {props.view == "LOGIN" && <Authenticate view={props.view} fadeBackground={true} /> }
                        {props.view == "SIGNUP" && <Authenticate view={props.view}  fadeBackground={true} /> }
                        {props.view == "FORGOT" && <Authenticate view={props.view}  fadeBackground={true} /> }
                        {props.view == "RESET" && <PasswordReset fadeBackground={true} /> }
                    </div>
                </div>
            </section>
            <DefaultFooter />
        </>
    )
}
export default LoginSignup