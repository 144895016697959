import Navbar from './components/toolbars/Navbar';
import {
    Routes,
    Route,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import DefaultFooter from './components/footer/DefaultFooter';
import Landing from './components/showcase/Landing';
import ThreeCard from './components/showcase/ThreeCard';
import CourseList from './components/course/CourseList';
import CourseDetails from './components/course/CourseDetails';
import LanguageRedirect from './components/panels/LanguageRedirect';
import PersistLogin from './components/authentication/PersistLogin';
import InstructorList from './components/instructor/InstructorList';
import LoginSignup from './components/panels/LoginSignup';
import Account from './components/user/Account';
import RequireAuth from './components/authentication/RequireAuth';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import FAQ from './components/panels/FAQ';
import AboutUs from './components/panels/AboutUs';
import { useSourceMutation } from './api/miscApiSlice';
import InstructorFooter from './components/footer/InstructorFooter';
import ScrollToTop from './components/utils/ScroolToTop';

const TRACKING_ID = "UA-245812616-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {

    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const [srcMutation] = useSourceMutation()

    useEffect(() => {
        if (searchParams.has("src")) {
            srcMutation(searchParams.get("src") + "")
            searchParams.delete("src");
            setSearchParams(searchParams);
        }
    })

    useEffect(() => {
        // track pageview with gtag / react-ga / react-ga4, for example:
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <>
            <Navbar />
            <ScrollToTop />
            <Routes>
                <Route element={<PersistLogin />}>
                    <Route path={"/:lang"}>
                        <Route index element={<><Landing /><ThreeCard /><DefaultFooter /></>} />
                        <Route path="login" element={<LoginSignup view="LOGIN" key={0} />} />
                        <Route path="signup" element={<LoginSignup view="SIGNUP" key={1} />} />
                        <Route path="forgot" element={<LoginSignup view="FORGOT" key={2} />} />
                        <Route path="faq" element={<FAQ />} />
                        <Route path="about" element={<AboutUs />} />
                        <Route path="password/:password_token" element={<LoginSignup view="RESET" key={2} />} />
                        <Route path="courses" element={<><CourseList /><InstructorFooter /></>} />

                        <Route element={<RequireAuth allowedRoles={["User"]} />}>
                            <Route path="account">
                                <Route index element={<Account />} />
                                <Route path="details" element={<Account />} />
                                <Route path="enrollments" element={<Account tab={"ENROLLMENTS"} />} />
                            </Route>
                        </Route>

                        <Route path="instructors" element={<InstructorList />} />
                        <Route path={"offering/:offering_id"}>
                            <Route index element={<CourseDetails />} />
                        </Route>
                    </Route>

                    <Route path="*" element={<LanguageRedirect />} />
                </Route>
            </Routes>
        </>
    )
}

export default App;