import { useTranslation } from 'react-i18next';
import ContactUsButton from '../utils/ContactUsButton';

const InstructorFooter = () => {
    const { t } = useTranslation();
    return (
        <footer className="py-3 my-1 container">
            <div className="nav border-bottom
                            pb-3 mb-1 row justify-content-center">
            </div>
            <p className="text-center text-muted pt-3">&copy; 2022 Jack Stubbs</p>

        </footer>
    )
}

export default InstructorFooter;