import { Outlet, Link, useLocation } from "react-router-dom"
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../../reducers/authSlice"
import { useRefreshMutation } from "../../api/authApiSlice"
import loadingMarkup from "../.."


const PersistLogin = () => {

    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)

    const [trueSuccess, setTrueSuccess] = useState(false)
    const location = useLocation()

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()


    useEffect(() => {

        if (effectRan.current === true || process.env.NODE_ENV !== 'development') { // React 18 Strict Mode

            const verifyRefreshToken = async () => {
                //console.log('verifying refresh token')
                try {
                    //const response = 
                    await refresh([])
                    //const { accessToken } = response.data
                    setTrueSuccess(true)
                }
                catch (err) {
                    //console.error(err)
                }
            }

            if (!token) verifyRefreshToken()
        }

        effectRan.current = true

        // eslint-disable-next-line
    }, [])


    let content: ReactElement | null = null
    if (isLoading) { //persist: yes, token: no
        //console.log('loading')
        if (location.pathname.includes("account")) {
            content = loadingMarkup
        } else {
            content = <Outlet />
        }
    } else if (isError) { //persist: yes, token: no
        // console.log('error')
        /* content = (
            <p className='errmsg'>
                {(error as any)?.data?.message}
                <Link to="/login">Please login again</Link>.
            </p>
        ) */
        content = <Outlet />
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        //console.log('success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        //console.log('token and uninit')
        //console.log(isUninitialized)
        content = <Outlet />
    }

    return content
}
export default PersistLogin