import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useDetailsQuery } from '../../api/authApiSlice';
import { useSubscriptionMutation } from '../../api/miscApiSlice';
import { ISubscribeRequest } from '../../interface/apiRequests';
import { IUser } from '../../interface/user';
import { useAppSelector } from '../../reducers/hooks';

interface props {
    target: string
} 

const SubscribeButton = (props: props) => {

    const { t } = useTranslation()

    let [form, updateform] = useState({
        email: "",
        attempted: false
    });

    let [modalState, changeModalState] = useState("FORM");
    const [postSubscription, { isLoading, isSuccess, isError, error }] = useSubscriptionMutation();
    const auth = useAppSelector(state => state.auth);

    var userData: IUser | null = null

    const userDetailsRequest = useDetailsQuery(undefined, { skip: !auth.uuid })
    if (auth.uuid != undefined && !userDetailsRequest.error && !userDetailsRequest.isLoading) {
        userData = userDetailsRequest.data ? userDetailsRequest.data["data"] as IUser : null
    }

    useEffect(() => {
        resetForm()
    }, [userData])


    function emailInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        updateform((prevState) => {
            return { ...prevState, email: event.target.value }
        });
    }

    function resetModalState(event: React.MouseEvent<HTMLElement>) {
        changeModalState("FORM");
    }

    function resetForm() {
        updateform((prevState) => {
            return {
                email: userData ? String(userData.email) : "",
                attempted: false
            }
        });
    }

    function isValid(data: String, type: String = "", override: Boolean = false) {
        let flag = false;
        let value = String(data).trim();

        if (!form.attempted && !override)
            return "";

        switch (type) {
            case "email":
                flag = validator.isEmail(value);
                break;
            default:
                flag = value !== "";
        }

        if (override)
            return flag;
        return flag ? "is-valid" : "is-invalid";
    }

    function formIsValid() {
        return isValid(form.email, "email", true);
    }

    async function submitForm(event: React.MouseEvent<HTMLButtonElement>) {

        if (!formIsValid()) {
            updateform((prevState) => {
                return { ...prevState, attempted: true }
            });
        } else {
            let submission: ISubscribeRequest = {
                email: form.email,
                target: props.target
            };

            changeModalState("LOADING");

            try { 
                const data = await postSubscription(submission).unwrap();
                if (data["data"] === true) {
                    changeModalState("SUCCESS");
                    resetForm();
                } else {
                    changeModalState("ERROR");
                }
            } catch (error) {
                changeModalState("ERROR");
            }
        }
    }

    return (
        <div className="flex-fill">
            {/* Display Button */}
            <button type="button" className="btn btn-success b-3 w-100
                        d-inline-flex flex-row justify-content-center align-items-center"
                data-bs-toggle="modal" data-bs-target="#subscriptionModal"
                onClick={resetModalState}>
                <div className='p-1'>
                    <i className="h3 bi bi-envelope-check"></i>
                </div>
                <div className='text-center p-1 mx-2'>
                    {t("subscribe", { ns: "validate" })}
                </div>
            </button>

            <div className="modal fade" id="subscriptionModal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true">
                {/* Subscribe Modal (Input) */}
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        {modalState === "FORM" &&
                            <>
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('subscribe')}</h4>
                                    <button type="button" className="btn-close" data-bs-target="#subscriptionModal" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                                </div>
                                {!userDetailsRequest.isLoading && <div className="modal-body">
                                    <form>

                                        <div className="mb-2">
                                            <label htmlFor="validateEmail" className="form-label text-start w-100">{t("subscribe_details", { ns: "validate" })}</label>
                                            <input type="email" className={"form-control " + isValid(form.email, "email")} id="validateEmail"
                                                onChange={emailInputHandler} value={form.email} />
                                            <div id="validateEmail" className="invalid-feedback">
                                                {t("validate_email", { ns: "validate" })}
                                            </div>
                                        </div>

                                        <div className="modal-footer row">
                                            <button type="button" className="btn btn-primary col"
                                                data-bs-target={formIsValid() && false && "#submittedModal"}
                                                data-bs-toggle={formIsValid() && false && "modal"}
                                                onClick={submitForm} >
                                                {t("subscribe_2", { ns: "validate" })}
                                            </button>
                                        </div>
                                    </form>
                                </div>}
                                {userDetailsRequest.isLoading && <div className="modal-body">
                                    {t("loading", { ns: "validate" })}
                                </div>}
                            </>
                        }

                        {/** Subscribe Modal (Submitted) */}
                        {modalState === "LOADING" && <>
                            <div className="modal-header justify-content-centered">
                                <h5 className="modal-title" id="submittedModalLabel">{t("sending_message", { ns: "validate" })}</h5>
                                <button type="button" className="btn-close" data-bs-target="#subscriptionModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center">
                                <div className="spinner-border text-primary m-4" role="status">
                                    <span className="visually-hidden">{t("loading", { ns: "validate" })}</span>
                                </div>
                            </div>
                            <div className="container mb-3">
                                <button type="button" className="btn btn-secondary center w-100" data-bs-target="#subscriptionModal" data-bs-dismiss="modal">{t("cancel", {ns: "validate"})}</button>
                            </div>
                        </>
                        }

                        {/** Subscribe Modal (Submitted) */}
                        {modalState === "SUCCESS" && <>
                            <div className="modal-header justify-content-centered">
                                <h5 className="modal-title" id="submittedModalLabel">{t("subscription_sent", { ns: "validate" })}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" data-bs-target="#subscriptionModal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h1 className='text-center'>🥳</h1>
                                <p className='text-center h6'>{t("subscription_sent_msg", { ns: "validate" })}</p>
                            </div>
                            <div className="container mb-3">
                                <button type="button" className="btn btn-secondary center w-100" data-bs-target="#subscriptionModal" data-bs-dismiss="modal">Close</button>
                            </div>
                        </>
                        }

                        {/** Subscribe Modal (Submitted) */}
                        {modalState === "ERROR" && <>

                            <div className="modal-header justify-content-centered">
                                <h5 className="modal-title" id="submittedModalLabel">{t("whoops", { ns: "validate" })}</h5>
                                <button type="button" className="btn-close" data-bs-target="#subscriptionModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h1 className='text-center'>❌</h1>
                                <p className='text-center h6'>
                                    {t("error_generic_message", { ns: "validate" })}
                                </p>
                            </div>
                            <div className="container mb-3">
                                <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal">{t("close", { ns: "validate" })}</button>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SubscribeButton;