import Modal from "bootstrap/js/dist/modal";
import i18next from "i18next";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { useNewPasswordMutation } from "../../api/authApiSlice";
import { INewPasswordRequest } from "../../interface/apiRequests";

interface LoginProps {
    fadeBackground: boolean
}

const PasswordReset = (props: LoginProps) => {
    const params = useParams()
    const navigate = useNavigate()
    var { t } = useTranslation();
    const password_token = params.password_token

    let [completionModalState, updateCompletionModalState] = useState("LOADING");
    const [errorDescription, updateErrorDescription] = useState("");
    const [reset] = useNewPasswordMutation();
    const languageCode = i18next.language;

    const [modal, updateModal] = useState<Modal | null>(null);

    useEffect(() => {
        var myModalEl = document.getElementById('resetFormComplete')!;
        var modal = Modal.getOrCreateInstance(myModalEl);
        updateModal(modal);
    }, []);

    useEffect(() => {
        resetForm()
    }, [password_token])

    const [form, updateForm] = useState({
        password: "",
        passwordConfirm: "",
        attempted: false
    });

    function resetForm() {
        updateForm({
            password: "",
            passwordConfirm: "",
            attempted: false
        })
    }

    function updateState(key: string, value: any) {
        updateForm((prevState) => {
            return { ...prevState, [key]: value }
        });
    }

    function isValid(data: String, type: String = "", override: Boolean = false, isLogin: Boolean = false) {
        let flag = false;
        let value = String(data).trim();

        if (!form.attempted && !override)
            return "";

        switch (type) {
            case "password":
                flag = validator.isStrongPassword(value, { minLength: 10, minLowercase: 0, minUppercase: 0, minNumbers: 1, minSymbols: 0, returnScore: false, })
                break;
            case "passwordConfirm":
                if (!isValid(form.password, "password", true)) { return ""; }
                flag = form.password === form.passwordConfirm;
                break;
            default:
                flag = value !== "";
        }

        if (override)
            return flag;
        if (!isLogin)
            return flag ? "is-valid" : "is-invalid";
        return flag ? "" : "is-invalid";
    }

    function formValid() {
        return isValid(form.password, "password", true) &&
            isValid(form.passwordConfirm, "passwordConfirm", true);
    }

    const handleResetAction = async (e: any) => {
        e.preventDefault()
        updateState("attempted", true)

        if (!formValid())
            return;

        modal?.show()
        updateCompletionModalState("LOADING")
        const request = {
            reset_token: password_token,
            pwd: form.password
        } as INewPasswordRequest

        try {
            const result = await reset(request).unwrap();
            if ("data" in result && result["data"] === true) {
                updateCompletionModalState("SUCCESS")
            } else {
                updateCompletionModalState("ERROR")
                updateErrorDescription(t(result["error"], { ns: "error" }))
            }
        } catch (error) {
            updateCompletionModalState("ERROR")
            updateErrorDescription(t("generic_error", { ns: "error" }))
        }
    }

    return (
        <>
            {/* Reset Form */}
            <div className={"row fadeIn " + (props.fadeBackground ? "rounded rounded-5 py-3 px-2" : "")}
                style={props.fadeBackground ? {
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    backdropFilter: "blur(10px)",
                    WebkitBackdropFilter: "blur(10px)",
                } : {}}>
                {completionModalState != "SUCCESS" && <div className="container col-lg-7 px-4">
                    <div className="card-title h4 text-center mb-3  pb-2 border-dark border border-1 border-top-0 border-left-0 border-right-0">
                        {t("set_a_password", { ns: "validate" })}
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-lock h5 align-self-center my-1" /></span>
                        <input type="password" className={"form-control " + isValid(form.password, "password")} placeholder={t("password", { ns: "validate" })} aria-label="password" aria-describedby="Password" onChange={(e: any) => { updateState("password", e.target.value) }} />
                        <div id="validateSignupPassword" className="invalid-feedback">
                            {t("validate_password_insuf", { ns: "validate" })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-lock h5 align-self-center my-1" /></span>
                        <input type="password" className={"form-control " + isValid(form.passwordConfirm, "passwordConfirm")} placeholder={t("password_again", { ns: "validate" })} aria-label="passwordVerification" aria-describedby="PasswordVerification" onChange={(e: any) => { updateState("passwordConfirm", e.target.value) }} />
                        <div id="validateSignupPassword2" className="invalid-feedback">
                            {t("validate_password", { ns: "validate" })}
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-dark w-100" onClick={handleResetAction}
                    >
                        {t("update_password", { ns: "validate" })}
                    </button>
                </div>}
                {completionModalState == "SUCCESS" && <div className="container col-lg-7 px-4">
                    <div className="card-title h4 text-center mb-3  pb-2 border-dark border border-1 border-top-0 border-left-0 border-right-0">
                        {t("login_new_password", { ns: "validate" })}
                    </div>
                    <button type="button" className="btn btn-outline-dark w-100" onClick={() => { navigate("/" + languageCode + "/login") }}
                    >
                        {t("go_to_login", { ns: "validate" })}
                    </button>
                </div>}
            </div>

            {/* Complete Modal */}
            <div className="modal fade" id="resetFormComplete" data-bs-backdrop="static" data-bs-keyboard={false} tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                {/** Loading */}
                {completionModalState === "LOADING" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t("resetting_password", { ns: "validate" })}</h5>
                        </div>
                        <div className="modal-body text-center">
                            <div className="spinner-border text-primary m-4" role="status">
                                <span className="visually-hidden">{t("loading", { ns: "validate" })}</span>
                            </div>
                        </div>
                    </div>
                </div>}

                {/** Success */}
                {completionModalState == "SUCCESS" && <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t("password_updated", { ns: "validate" })}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="text-center fs-5">{t("success", { ns: "validate" })}</div>
                            <div className="card-text text-center my-2">{t("password_success_message", { ns: "validate" })}</div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary w-100" data-bs-dismiss="modal" onClick={() => { navigate("/" + languageCode + "/login") }}>{t("login", { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}

                {/** Error */}
                {completionModalState === "ERROR" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t("whoops", { ns: "validate" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h1 className='text-center'>❌</h1>
                            <p className='text-center h6'>{errorDescription} </p>
                        </div>
                        <div className="container mb-3">
                            <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal">{t("close", { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export default PasswordReset;
