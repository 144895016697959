import { useTranslation } from 'react-i18next';
import ContactUsButton from './ContactUsButton';
import SubscribeButton from './SubscribeButton';

interface props {
    prompt: string,
    target: string
}

const SubscribeCard = (props: props) => {

    const { t } = useTranslation()

    return (
        <div className="d-flex rounded align-items-center justify-content-center row row-cols-1 row-cols-xl-12">
            <p className="h5 pb-0 mb-0 mb-2 mb-xl-0 col-xl-7 col text-center">
                {props.prompt}
            </p>
            <div className="d-flex mt-2 col col-xl-5 mt-xl-0 align-items-center">
                <SubscribeButton target={props.target} />
            </div>
        </div>
    );

}

export default SubscribeCard;