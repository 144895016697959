import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, FetchArgs, fetchBaseQuery,  } from '@reduxjs/toolkit/query/react'
import { RootState } from '..';
import { setCredentialsLogout, setCredentials } from '../reducers/authSlice';

export const PROTECTED_ROUTES = "/s"

const baseQuery = fetchBaseQuery({
    // Error: Withcredentials not working once this is changed to IP
    //baseUrl: 'http://localhost:10000/api/',
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    let result = await baseQuery(args, api, extraOptions)

    if ((result?.error as any)?.originalStatus === 403) {
        //console.log('sending refresh token')
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('auth/refresh', api, extraOptions)
        //console.log(refreshResult)

        if (refreshResult?.data) {

            // store the new token 
            api.dispatch(setCredentials(refreshResult.data as {}))

            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(setCredentialsLogout())
        }
    }

    return result
}


export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User', 'Students', 'Enrollments'],
    endpoints: builder => ({})
})