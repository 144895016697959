import { useTranslation } from 'react-i18next';
import ContactUsButton from './ContactUsButton';

interface props {
    prompt: string
}

const ContactCard = (props: props) => {

    const { t } = useTranslation()

    return (
        <div className="d-flex rounded align-items-center justify-content-center row row-cols-1 row-cols-md-2">
            <p className="h5 mb-0 mb-2 mb-md-0  col text-center">
                {props.prompt}
            </p>
            <div className="col d-flex mt-2 mt-md-0 align-items-center">
                <ContactUsButton />
            </div>
        </div>
    );

}

export default ContactCard;