import i18next from "i18next";
import { Navigate } from "react-router-dom";

const LanguageRedirect = () => {
    const languageCode = i18next.language;
    const supportedLngs = ["en", "ko"]
    let exists = false;

    for(let i = 0; i < supportedLngs.length; i++) {
        if (supportedLngs[i] == languageCode.toString()) {
            exists = true;
            break;
        }
    }

    return (
        <Navigate to={exists ? `/${languageCode}` : "/ko"} />
    )
}

export default LanguageRedirect;