import { useTranslation } from "react-i18next";
import { useInstructorsQuery } from "../../api/instructorApiSlice";
import { IInstructor } from "../../interface/instructor";
import InstructorFooter from "../footer/InstructorFooter";
import ContactUsButton from "../utils/ContactUsButton";
import InstructorCard from "./InstructorCard";


const InstructorList = () => {

    var { t } = useTranslation();
    var instructors: IInstructor[] | null = null

    const { data, error, isLoading } = useInstructorsQuery({})

    if (error) {
        // TODO: Show error message
    }

    if (!isLoading && data) {
        try {
            const temp: IInstructor[] = Object.values(data["data"] as IInstructor[])
            instructors = temp
        } catch (error) {
            instructors = null
            // show error
        }
    }

    return (
        <>
            <div className="bg-light min-vh-100">
                <section className="p-3 container">
                    {/* Course List Title */}
                    <div className="d-flex align-items-center justify-content-between 
                                p-3 my-3 text-muted border-secondary shadow-sm rounded-2"
                        style={{
                            backgroundColor: "var(--bs-purple)",
                        }}>
                        <i className="bi bi-person-circle h1 text-white"></i>
                        <h1 className="h3 mb-0 text-light lh-1">{t('header_instructors')}</h1>
                        <i className="bi bi-person-circle h1 text-white"></i>
                    </div>
                    {!instructors &&
                        <div className="bg-light d-flex align-items-center justify-content-center" style={{ height: "60vh" }}>
                            <div className="spinner-border" style={{ width: "5rem", height: "5rem" }} role="status">
                            </div>
                        </div>
                    }
                    {instructors &&
                        instructors.map((instructor, index) =>
                            <InstructorCard key={index} instructor={instructor} show_prompt={true} />
                        )
                    }


                    <div className="col-12 mt-5 mb-4 d-flex rounded align-items-center justify-content-evenly fadeIn">
                        <p className="h5 mb-0 col-7 col-md-4 text-center px-2">
                            {t('questions', { ns: "instructor" })}
                        </p>
                        <div className="col col-md-3 d-flex align-items-center">
                            <ContactUsButton />
                        </div>
                    </div>
                </section >



                <InstructorFooter />
            </div >
        </>

    )
}

export default InstructorList;