import i18next from "i18next";
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { RootState } from "../..";
import { IAuthentication } from "../../interface/apiRequests";
import { useAppSelector } from "../../reducers/hooks";

type Props = {
    allowedRoles?: string[]
}
const RequireAuth = ({ allowedRoles }: Props) => {
    const auth = useAppSelector(state => state.auth) as IAuthentication;
    const location = useLocation();
    const languageCode = i18next.language;

    return (
        auth.roles?.find(role => allowedRoles?.includes(role)) 
            ? <Outlet />
            : auth.uuid
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to={"/" + languageCode + "/login"} state={{ from: location }} replace />
    );
}

export default RequireAuth;