import { ISubscribeRequest } from "../interface/apiRequests";
import { apiSlice } from "./apiSlice";

const BASE_URL_OFFERING = "/m/"

export const miscApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        source: builder.mutation({
            query: (src: string) => ({
                url: BASE_URL_OFFERING + 'src',
                method: 'POST',
                body: {"src" : src}
            })
        }),
        subscription: builder.mutation({
            query: (request: ISubscribeRequest) => ({
                url: BASE_URL_OFFERING + 'subscribe',
                method: 'POST',
                body: request
            })
        }),
    }),
    overrideExisting: false,
})

export const {
    useSourceMutation,
    useSubscriptionMutation
} = miscApiSlice