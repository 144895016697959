import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IEnrollment } from '../../interface/user';
import { Translation, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DateTime } from 'luxon';

interface props {
    enrollment: IEnrollment
}

const EnrollmentDetails = (props: props) => {
    const { t } = useTranslation();
    const [isLoading, updateIsLoading] = useState(true)
    const nameSpace = 'course_' + props.enrollment.session.offering?.course.id
    const languageCode = i18next.language;

    useEffect(() => {
        i18next.loadNamespaces(['enrollment_details', nameSpace]).then(() => { updateIsLoading(false) });
    })

    function getStatus(level: String, isText: boolean) {
        switch (level) {
            case 'Reserved':
                return isText ? t('status_reserved', { ns: "enrollment_details" }) : "text-danger"
            case 'Paid':
                return isText ? t('status_paid', { ns: "enrollment_details" }) : "text-success"
            case 'Complete':
                return isText ? t('status_complete', { ns: "enrollment_details" }) : "text-success"
            default:
                return isText ? t('status_canceled', { ns: "enrollment_details" }) : "text-danger"
        }
    }

    return (
        <>
            {!isLoading && <div className="card">
                <div className="card-header">
                    <div className="card-title h5 text-center">
                        <Translation ns={nameSpace}>
                            {(t) => <>{t('course_name')}</>}
                        </Translation>
                    </div>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('name', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7">{props.enrollment.student.name}</li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('location', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7"><Translation ns={nameSpace}>{(t) => <>{t('course_mode')}</>}</Translation></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('duration', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7">
                                {t('course_duration_week', { ns: "course", weeks: props.enrollment.session.offering?.course.duration })}
                                {t('course_duration_hour_short', { ns: "course", hour: props.enrollment.session.offering?.course.weekly_hours })}
                                </li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4"><strong>{t('starting_week', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7">{DateTime.fromISO(props.enrollment.session.offering?.start_week!).setLocale(languageCode).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('class_time', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7 ">{DateTime.fromISO(props.enrollment.session.time!).toLocal().setLocale(languageCode).toFormat("cccc h") + " " + DateTime.fromISO(props.enrollment.session.time!).toLocal().setLocale('en').toFormat("a")}</li>
                        </ul>
                    </li>
                    <li className={"list-group-item " + getStatus(props.enrollment.status, false)}>
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('status', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7 ">{getStatus(props.enrollment.status, true)}</li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('price', { ns: "enrollment_details" })}:</strong></li>
                            <li className="list-inline-item col-7 ">₩{(props.enrollment.session.offering?.course.price! * 1).toLocaleString()}</li>
                        </ul>
                    </li>
                </ul>
            </div>}
            {isLoading && <div className="card">
                <div className="card-header">
                    <div className="card-title h5 text-center placeholder col-12"></div>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item ">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                </ul>
            </div>}
        </>
    );
};

export default EnrollmentDetails;