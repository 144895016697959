import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useOfferingQuery } from '../../api/courseApiSlice';
import { IOffering } from '../../interface/course';
import ContactUsButton from '../utils/ContactUsButton';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';
import InstructorCard from '../instructor/InstructorCard';
import { DateTime } from 'luxon';


const AboutUs = () => {
    var { t } = useTranslation();

    return (
        <>
            <section className="bg-light overflow-scroll min-vh-100">


                {/* Title Pane */}
                <div className="position-relative" style={{
                    overflow: "hidden",
                    maxHeight: "max(20vh, 35vmin)",
                }}>
                    <img src={"/img/team.jpg"} className="img-fluid"
                        style={{
                            objectFit: "cover",
                            minWidth: "100%",
                            height: "auto"
                        }} />
                    <div className='position-absolute pt-2 p-1 px-2 m-3 bottom-0 col-8 col-sm-6 col-lg-4
                                    bg-secondary rounded bg-opacity-75'>
                        <p className="h2 text-light">
                            {t("meet_the_team", {ns: "about_us"})}
                        </p>
                    </div>
                </div>

                {/* Course Content */}
                <div className="container pb-2 mb-5 mt-3 overflow-scroll">
                    {/* Description Card */}
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">{t("who_are_we", {ns: "about_us"})}</h3>
                            <div className="fs-5 card-text">{t("who_are_we_ans", {ns: "about_us"})}</div>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">{t("what_do_we_offer", {ns: "about_us"})}</h3>
                            <div className="fs-5 card-text">{t("what_do_we_offer_ans", {ns: "about_us"})}</div>
                        </div>
                        <div className="card-body">
                            <h3 className="card-title">{t("why_learn", {ns: "about_us"})}</h3>
                            <div className="fs-5 card-text">{t("why_learn_answer", {ns: "about_us"})}</div>
                        </div>
                    </div>

                    {/* Contact Us Button */}
                    <div className="col-12 mt-3 mb-4 mt-5 d-flex rounded align-items-center justify-content-center fadeIn">
                        <p className="h5 mb-0 col-7 col-md-4 text-center px-2">
                        {t("ask_question", {ns: "about_us"})}
                        </p>
                        <div className="col col-md-3 d-flex align-items-center">
                            <ContactUsButton />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;