import { apiSlice } from "./apiSlice";

const BASE_URL_INSTRUCTOR = "/instructor/"

export const instructorApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        instructors: builder.query({
            query: () => ({
                url: BASE_URL_INSTRUCTOR + "all",
                method: 'GET'
            })
        })
    }),
    overrideExisting: false,
})

export const {
    useInstructorsQuery,
} = instructorApiSlice