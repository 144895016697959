import { ILoginRequest, INewPasswordRequest, IPasswordResetRequest, ISignupRequest } from "../interface/apiRequests";
import { setAccessToken, setCredentials } from "../reducers/authSlice";
import { apiSlice, PROTECTED_ROUTES } from "./apiSlice";

const BASE_URL = "/auth/"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        signup: builder.mutation({
            query: (request: ISignupRequest) => ({
                url: BASE_URL + 'signup',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['User'],
        }),
        login: builder.mutation({
            query: (request: ILoginRequest) => ({
                url: BASE_URL + 'login',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['User'],
        }),
        newPassword: builder.mutation({
            query: (request: INewPasswordRequest) => ({
                url: BASE_URL + 'password',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['User'],
        }),
        resetPassword: builder.mutation({
            query: (request: IPasswordResetRequest) => ({
                url: BASE_URL + 'reset',
                method: 'POST',
                body: request
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: BASE_URL + 'logout',
                method: 'POST'
            }),
            invalidatesTags: ['User'],
        }),
        refresh: builder.mutation({
            query: () => ({
                url: BASE_URL + 'refresh',
                method: 'GET',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setCredentials(data))
                } catch (err) {
                    //console.log(err)
                }
            }
        }),
        details: builder.query({
            query: () => ({
                url: PROTECTED_ROUTES + BASE_URL + 'details',
                method: 'GET'
            }),
            providesTags: ['User', 'Students']
        }),

        enrollments: builder.query({
            query: () => ({
                url: PROTECTED_ROUTES + BASE_URL + 'enrollments',
                method: 'GET'
            }),
            providesTags: ["Enrollments"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useSignupMutation,
    useLoginMutation,
    useLogoutMutation,
    useDetailsQuery,
    useRefreshMutation,
    useEnrollmentsQuery,
    useNewPasswordMutation,
    useResetPasswordMutation
} = authApiSlice