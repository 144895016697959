import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IStudent } from '../../interface/user';
import StudentCard from './StudentCard';

interface props {
    students?: IStudent[]
}

const StudentDetails = (props: props) => {
    const { t } = useTranslation();
    const [nsIsLoading, updateNSIsLoading] = useState(true)
    const [isLoading, updateIsLoading] = useState(true)
    const navigate = useNavigate()
    const languageCode = i18next.language;

    useEffect(() => {
        i18next.loadNamespaces('student_details').then(() => { updateNSIsLoading(false) });
    })

    useEffect(() => {
        if (props.students && !nsIsLoading) {
            updateIsLoading(false)
        } else {
            updateIsLoading(true)
        }
    }, [props.students, nsIsLoading])

    return (
        <>
            {!isLoading && <div className="container fadeIn">
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <p className="h4 border-bottom pb-2 mb-2">{t('title', { ns: "student_details" })}</p>
                    <div className="row row-cols-1 row-cols-lg-2 pt-2">
                        {props.students?.length! > 0 &&
                            <>{
                                props.students?.map((student, index) =>
                                    <div key={index} className="col col-lg-6 my-2">
                                        <StudentCard student={student} />
                                    </div>
                                )
                            }</>
                        }
                        {props.students?.length! == 0 &&
                            <>
                                <div className="col col-xl-5 my-2 my-xl-0 d-flex align-items-center justify-content-center">
                                    <p className="h4 px-3 m-0 text-center">
                                        {t("no_students", { ns: "student_details" })}
                                    </p>
                                </div>
                                <div className="col col-xl-3 my-2 my-xl-0">
                                    <button type="button" className="btn btn-success b-3 w-100
                        d-inline-flex flex-row justify-content-center align-items-center"
                                        onClick={() => { navigate("/" + languageCode + "/courses") }}>
                                        <div className='p-1'>
                                            <i className="h3 bi bi-ui-checks"></i>
                                        </div>
                                        <div className='fs-5 text-center p-1'>
                                            {t("enroll_now", { ns: "student_details" })}
                                        </div>
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>}
            {isLoading && <div className="container fadeIn">
                <div className="my-3 p-3 bg-body rounded shadow-sm placeholder-glow">
                    <p className="h4 border-bottom pb-2 mb-2 placeholder col-5"></p>
                    <div className="row row-cols-1 row-cols-lg-2">
                        <div className="col col-lg-6 my-2">
                            <div className="card placeholder-glow">
                                <div className="card-header text-left">
                                    <div className="card-title h5  placeholder col-11"></div>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default StudentDetails;