import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { RootState } from '../..';
import { useDetailsQuery } from '../../api/authApiSlice';
import { usePostContactMessageMutation } from '../../api/contactApiSlice';
import { IContactSubmission } from '../../interface/models';
import { IUser, IEnrollment } from '../../interface/user';
import { useAppSelector } from '../../reducers/hooks';

const ContactUsButton = () => {

    const { t } = useTranslation()

    let [form, updateform] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
        attempted: false
    });

    let [modalState, changeModalState] = useState("FORM");
    const [postContactMessage, { isLoading, isSuccess, isError, error }] = usePostContactMessageMutation();
    const auth = useAppSelector(state => state.auth);

    var userData: IUser | null = null

    const userDetailsRequest = useDetailsQuery(undefined, { skip: !auth.uuid })
    if (auth.uuid != undefined && !userDetailsRequest.error && !userDetailsRequest.isLoading) {
        userData = userDetailsRequest.data ? userDetailsRequest.data["data"] as IUser : null
    }

    useEffect(() => {
        resetForm()
    }, [userData])

    function nameInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        updateform((prevState) => {
            return { ...prevState, name: event.target.value }
        });
    }

    function phoneInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        updateform((prevState) => {
            return { ...prevState, phone: event.target.value }
        });
    }

    function emailInputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        updateform((prevState) => {
            return { ...prevState, email: event.target.value }
        });
    }

    function messageInputHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        updateform((prevState) => {
            return { ...prevState, message: event.target.value }
        });
    }

    function resetModalState(event: React.MouseEvent<HTMLElement>) {
        changeModalState("FORM");
    }

    function resetForm() {
        updateform((prevState) => {
            return {
                name: auth.uuid ? String(auth.name) : "",
                phone: userData ? String(userData.phone) : "",
                email: userData ? String(userData.email) : "",
                message: "",
                attempted: false
            }
        });
    }

    function isValid(data: String, type: String = "", override: Boolean = false) {
        let flag = false;
        let value = String(data).trim();

        if (!form.attempted && !override)
            return "";

        switch (type) {
            case "email":
                flag = validator.isEmail(value);
                break;
            case "phone":
                flag = validator.isMobilePhone(value);
                break;
            default:
                flag = value !== "";
        }

        if (override)
            return flag;
        return flag ? "is-valid" : "is-invalid";
    }

    function formIsValid() {
        return isValid(form.name, "", true) &&
            (isValid(form.email, "email", true) || isValid(form.phone, "phone", true)) &&
            isValid(form.message, "", true);
    }

    async function submitForm(event: React.MouseEvent<HTMLButtonElement>) {

        if (!formIsValid()) {
            updateform((prevState) => {
                return { ...prevState, attempted: true }
            });
        } else {
            let submission: IContactSubmission = {
                name: form.name,
                phone: form.phone === "" ? undefined : form.phone,
                email: form.email === "" ? undefined : form.email,
                message: form.message,
                user: userData ? userData.uuid : undefined
            };

            changeModalState("LOADING");

            try {
                const data = await postContactMessage(submission).unwrap();
                if (data["result"] === "success") {
                    changeModalState("SUCCESS");
                    resetForm();
                } else {
                    changeModalState("ERROR");
                }
            } catch (error) {
                changeModalState("ERROR");
            }
        }
    }

    return (
        <div className="flex-fill">
            {/* Display Button */}
            <button type="button" className="btn btn-outline-primary b-3 w-100
                        d-inline-flex flex-row justify-content-center align-items-center"
                data-bs-toggle="modal" data-bs-target="#contactUsModal"
                onClick={resetModalState}>
                <div className='p-1'>
                    <i className="h3 bi bi-chat-dots"></i>
                </div>
                <div className='text-center p-1'>
                    {t('contact_us')}
                </div>
            </button>

            <div className="modal fade" id="contactUsModal" tabIndex={-1} aria-labelledby="contactUsModalLabel" aria-hidden="true">
                {/* Contact Us Modal (Input) */}
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        {modalState === "FORM" &&
                            <>
                                <div className="modal-header">
                                    <h4 className="modal-title">{t("contact_us", { ns: "validate" })}</h4>
                                    <button type="button" className="btn-close" data-bs-target="#contactUsModal" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}></button>
                                </div>
                                {!userDetailsRequest.isLoading && <div className="modal-body">
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="validateName" className="form-label">{t("name", { ns: "validate" })}</label>
                                            <input type="text" className={"form-control " + isValid(form.name)} id="validateName" disabled={auth.uuid != null}
                                                onChange={nameInputHandler} value={form.name} />
                                            <div id="validateName" className="invalid-feedback">
                                                {t("name_val", { ns: "validate" })}
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="validatePhone" className="form-label">{t("phone", { ns: "validate" })}:</label>
                                            <input type="text" className={"form-control " + isValid(form.phone, "phone")} id="validatePhone" disabled={auth.uuid != null}
                                                onChange={phoneInputHandler} value={form.phone} />
                                            <div id="validatePhone" className="invalid-feedback">
                                                {t("validate_phone", { ns: "validate" })}
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="validateEmail" className="form-label">{t("email", { ns: "validate" })}:</label>
                                            <input type="email" className={"form-control " + isValid(form.email, "email")} id="validateEmail"
                                                onChange={emailInputHandler} value={form.email} disabled={auth.uuid != null} />
                                            <div id="validateEmail" className="invalid-feedback">
                                                {t("validate_email", { ns: "validate" })}
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="validateMessage" className="form-label">{t("message", { ns: "validate" })}</label>
                                            <textarea className={"form-control " + isValid(form.message)} id="validateMessage"
                                                onChange={messageInputHandler} value={form.message}
                                                style={{
                                                    height: "15vh",
                                                }}
                                            />
                                            <div id="validateMessage" className="invalid-feedback">
                                                {t("message_val", { ns: "validate" })}

                                            </div>
                                        </div>

                                        <div className="modal-footer row">
                                            <button type="button" className="btn btn-secondary col"
                                                data-bs-target="#contactUsModal" data-bs-dismiss="modal" onClick={resetForm}>{t("cancel", {ns: "validate"})}</button>
                                            <button type="button" className="btn btn-primary col-8"
                                                data-bs-target={formIsValid() && false && "#submittedModal"}
                                                data-bs-toggle={formIsValid() && false && "modal"}
                                                onClick={submitForm} >
                                                {t("send_message", { ns: "validate" })}
                                            </button>
                                        </div>
                                    </form>
                                </div>}
                                {userDetailsRequest.isLoading && <div className="modal-body">
                                    {t("loading", { ns: "validate" })}
                                </div>}
                            </>
                        }

                        {/** Contact Us Modal (Submitted) */}
                        {modalState === "LOADING" && <>
                            <div className="modal-header justify-content-centered">
                                <h5 className="modal-title" id="submittedModalLabel">{t("sending_message", { ns: "validate" })}</h5>
                                <button type="button" className="btn-close" data-bs-target="#contactUsModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center">
                                <div className="spinner-border text-primary m-4" role="status">
                                    <span className="visually-hidden">{t("loading", { ns: "validate" })}</span>
                                </div>
                            </div>
                            <div className="container mb-3">
                                <button type="button" className="btn btn-secondary center w-100" data-bs-target="#contactUsModal" data-bs-dismiss="modal">{t("cancel", {ns: "validate"})}</button>
                            </div>
                        </>
                        }

                        {/** Contact Us Modal (Submitted) */}
                        {modalState === "SUCCESS" && <>
                            <div className="modal-header justify-content-centered">
                                <h5 className="modal-title" id="submittedModalLabel">{t("message_sent", { ns: "validate" })}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" data-bs-target="#contactUsModal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h1 className='text-center'>🥳</h1>
                                <p className='text-center h6'>{t("message_sent_des", { ns: "validate" })}</p>
                            </div>
                            <div className="container mb-3">
                                <button type="button" className="btn btn-secondary center w-100" data-bs-target="#contactUsModal" data-bs-dismiss="modal">Close</button>
                            </div>
                        </>
                        }

                        {/** Contact Us Modal (Submitted) */}
                        {modalState === "ERROR" && <>

                            <div className="modal-header justify-content-centered">
                                <h5 className="modal-title" id="submittedModalLabel">{t("whoops", { ns: "validate" })}</h5>
                                <button type="button" className="btn-close" data-bs-target="#contactUsModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h1 className='text-center'>❌</h1>
                                <p className='text-center h6'>
                                    {t("error_generic_message", { ns: "validate" })}
                                </p>
                            </div>
                            <div className="container mb-3">
                                <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal">{t("close", { ns: "validate" })}</button>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ContactUsButton;