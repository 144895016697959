// Stepper
import Stepper from "bs-stepper";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "bs-stepper/dist/css/bs-stepper.min.css";
import "bs-stepper/dist/js/bs-stepper.min.js";
import { RootState } from '../..';
import { addStudent, deleteStudent, resetStudents, updateStudent } from "../../reducers/courseSlice";
import { IEnolledStudent, IEnrollmentRequest, IOffering, ISession } from "../../interface/course";
import StudentDetailsModal, { StudentDetailModalHooks } from "../utils/StudentDetailsModal";
import { DateTime } from "luxon";
import Authenticate from "../authentication/Authenticate";
import i18next from "i18next";
import { IStudent, IUser } from "../../interface/user";
import { useEnrollMutation } from "../../api/courseApiSlice";
import { useAppDispatch, useAppSelector } from "../../reducers/hooks";
import { useDetailsQuery } from "../../api/authApiSlice";
import { useNavigate } from "react-router-dom";
import { Translation, useTranslation } from "react-i18next";
import SubscribeCard from "../utils/SubscribeCard";
import { IEnrollmentsRequest } from "../../interface/apiRequests";

interface CalendarItem {
    dateTime: DateTime,
    isFull: boolean,
    session: ISession
}

interface Props {
    offering: IOffering
}

const CourseRegistration = (props: Props) => {
    const NUMBER_OF_STEPS = 5;
    const languageCode = i18next.language;
    const navigate = useNavigate()
    const { t } = useTranslation()
    var enrollments = []

    let [currentStep, updatecurrentStep] = useState(1);
    let [showEnrollChildren, updateShowEnrollChildren] = useState(false);
    let [completionModalState, updateCompletionModalState] = useState("LOADING");
    const [errorDescription, updateErrorDescription] = useState("");
    const [enroll] = useEnrollMutation();
    let [selectedStudent, updateSelectedStudent] = useState(-1);
    const auth = useAppSelector(state => state.auth);

    /**************** He who be afraid of time shalt look no further *********************/
    const CLASS_TIMES = new Map();
    let DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    // set some inital defaults which will be overwritten
    const TIME_RANGE = { hour_range: 6, day_range: 5, starting_point: DateTime.fromSQL("2022-10-03 15:00:00 Asia/Seoul") };

    var earliest_time = undefined;
    var earliest_date = undefined;
    var latest_time = undefined;
    var latest_date = undefined;

    for (var i = 0; i < props.offering.sessions.length; i++) {
        var session = props.offering.sessions[i]
        var time = DateTime.fromISO(session.time)
        CLASS_TIMES.set(time.toUnixInteger(), props.offering.sessions[i])
        if (earliest_time == undefined || time.toLocal().hour < earliest_time.hour) (
            earliest_time = time.toLocal()
        )
        if (latest_time == undefined || time.toLocal().hour > latest_time.hour) (
            latest_time = time.toLocal()
        )
        if (earliest_date == undefined || time.toLocal() < earliest_date) (
            earliest_date = time.toLocal()
        )
        if (latest_date == undefined || time.toLocal() > latest_date) (
            latest_date = time.toLocal()
        )
    }

    if (earliest_date && latest_date && earliest_time && latest_time) {
        if (Math.abs(latest_date.weekday - earliest_date.weekday) > TIME_RANGE.day_range) {
            TIME_RANGE.day_range = latest_date.weekday - earliest_date.weekday
        }
        if ((latest_time.hour - earliest_time.hour) + 1 > TIME_RANGE.hour_range) {
            TIME_RANGE.hour_range = (latest_time.hour - earliest_time.hour) + 1
        }

        DAYS_OF_WEEK = []
        for (var i = 0; i < TIME_RANGE.day_range; i++) {
            DAYS_OF_WEEK.push(earliest_date.plus({ days: i }).setLocale(languageCode).toLocaleString({ weekday: 'short' }))
        }

        TIME_RANGE.starting_point = DateTime.local(earliest_date?.year, earliest_date?.month, earliest_date?.day, earliest_time?.hour)
    }

    const DEFAULT_CAL_ITEM = { dateTime: TIME_RANGE.starting_point.plus({ hour: -1 }), isFull: true } as CalendarItem;
    let [selectedTime, updateSelectedTime] = useState(DEFAULT_CAL_ITEM);

    const AVAILABLE_BTN_CLASS = "btn-info";
    const SELECTED_BTN_CLASS = "btn-success";

    const dispatch = useAppDispatch()
    const students = useSelector((state: RootState) => state.course.students);

    let showStepperButtons: Boolean = ((currentStep === 1 && false) ||
        (currentStep === 2 && (showEnrollChildren && students.length > 0)) ||
        currentStep > 2) && completionModalState !== "SUCCESS";

    let disableNextButton: boolean =
        (currentStep == 3 && students.filter((student) => student.class_time === undefined).length !== 0);


    var userData: IUser | undefined = undefined
    const userDetailsRequest = useDetailsQuery(undefined, { skip: !auth.uuid })
    if (!userDetailsRequest.error && !userDetailsRequest.isLoading) {
        userData = userDetailsRequest.data ? userDetailsRequest.data["data"] as IUser : undefined
    }

    useEffect(() => {
        if (auth.token && currentStep === 1) {
            nextStep();
        } else if (!auth.token) {
            updatecurrentStep(1);
            new Stepper(document.querySelector('.bs-stepper')!).to(1);
            scrollToStep(1);
        }
    }, [auth])

    function nextStep() {
        if (currentStep === NUMBER_OF_STEPS) {
            /* Submit p */
            submitEnrollmentRequest()
        } else {
            updateSelectedStudent(-1);
            updatecurrentStep(currentStep + 1);

            new Stepper(document.querySelector('.bs-stepper')!).to(currentStep + 1);
            scrollToStep(currentStep + 1);
        }
    }

    function previousStep() {
        if (currentStep === 1) {
            return;
        }

        updatecurrentStep(currentStep - 1);

        new Stepper(document.querySelector('.bs-stepper')!).to(currentStep - 1);
        scrollToStep(currentStep - 1);
    }

    function scrollToStep(step: number) {
        const stepperContainer = document.getElementById("sh-" + step);
        stepperContainer!.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
    }

    function resetEnrolledStudents() {
        dispatch(resetStudents());

        const day = selectedTime.dateTime.weekday;
        const time = selectedTime.dateTime.hour;

        const currentButton = document.querySelector("#availability-" + day + "-" + time);
        if (currentButton) {
            currentButton.classList.add(AVAILABLE_BTN_CLASS);
            currentButton.classList.remove(SELECTED_BTN_CLASS);
        }

        updateSelectedTime(DEFAULT_CAL_ITEM);
    }

    function enrollChildren(_event: any) {
        if (showEnrollChildren == false) {
            resetEnrolledStudents();
        }
        updateShowEnrollChildren(true);
    }

    function enrollMyself(_event: any) {
        updateShowEnrollChildren(false);
        resetEnrolledStudents();

        const student = {
            name: auth ? auth.name : "",
            is_account: true
        } as IEnolledStudent
        dispatch(addStudent(student));

        nextStep();
    }

    function removeStudent(index: number) {
        if (index == selectedStudent) {
            updateSelectedStudent(-1);
        }
        dispatch(deleteStudent(index));
    }

    function isAvailable(calendarItem: CalendarItem) {
        return !calendarItem.isFull;
    }

    function getCalendarButtonClass(calendarItem: CalendarItem) {
        const available = isAvailable(calendarItem);
        return available ? "btn-info text-white" : "btn-outline-secondary";
    }

    function selectTime(calendarItem: CalendarItem, preventUpdateStudent: boolean = false) {
        const day = calendarItem.dateTime.weekday;
        const time = calendarItem.dateTime.hour;

        var newSelectedTime: DateTime | undefined;

        if (selectedTime.dateTime.equals(calendarItem.dateTime)) {
            const currentButton = document.querySelector("#availability-" + day + "-" + time);
            currentButton?.classList.add(AVAILABLE_BTN_CLASS);
            currentButton?.classList.remove(SELECTED_BTN_CLASS);
            newSelectedTime = (undefined);
            calendarItem = DEFAULT_CAL_ITEM;
        } else {
            if (selectedTime.dateTime.day !== undefined) {
                const oldButton = document.querySelector("#availability-" + selectedTime.dateTime.weekday + "-" + String(selectedTime.dateTime.hour));
                oldButton?.classList.add(AVAILABLE_BTN_CLASS);
                oldButton?.classList.remove(SELECTED_BTN_CLASS);
            }

            const currentButton = document.querySelector("#availability-" + day + "-" + time);
            currentButton?.classList.remove(AVAILABLE_BTN_CLASS);
            currentButton?.classList.add(SELECTED_BTN_CLASS);

            newSelectedTime = (calendarItem.dateTime);
        }

        updateSelectedTime(calendarItem);

        /* If there are several students */
        if (!preventUpdateStudent) {
            if (students.length > 0) {
                const selected = selectedStudent === -1 ? 0 : selectedStudent;
                const tempStudent: IEnolledStudent = { ...students[selected], class_time: newSelectedTime?.toISO(), session: calendarItem.session };

                dispatch(updateStudent({ id: selected, student: tempStudent }));
            }
        }
    }

    function generateCalendar(): CalendarItem[][] {
        const numOfDays = TIME_RANGE.day_range;
        const numOfHours = TIME_RANGE.hour_range;

        var calendar: CalendarItem[][] = [];

        for (var hourOffset = 0; hourOffset < numOfHours; hourOffset++) {
            var hourSet: CalendarItem[] = [];
            for (var dayOffset = 0; dayOffset < numOfDays; dayOffset++) {
                const dateTime = TIME_RANGE.starting_point.plus({ days: dayOffset, hours: hourOffset });
                const session = CLASS_TIMES.has(dateTime.toUnixInteger()) ? CLASS_TIMES.get(dateTime.toUnixInteger()) : undefined;

                hourSet.push({
                    dateTime: dateTime,
                    isFull: session ? session.classTime : true,
                    session: session
                });
            }
            calendar.push(hourSet);
        }

        return calendar;
    }

    function selectStudent(index: number) {
        updateSelectedStudent(index);

        if (students[index].class_time == undefined) {
            selectTime(selectedTime, true);
        } else {
            // Artificaually generate calendar option
            const artifical = { dateTime: DateTime.fromISO(students[index].class_time!), isFull: CLASS_TIMES.get(DateTime.fromISO(students[index].class_time!).toUnixInteger()).dateTime } as CalendarItem;
            if (selectedTime.dateTime != artifical.dateTime)
                selectTime(artifical, true);
        }

    }

    async function submitEnrollmentRequest() {
        updateCompletionModalState("LOADING")
        const requests: IEnrollmentRequest[] = []
        for (var i = 0; i < students.length; i++) {
            const student = {
                uuid: students[i].uuid ? students[i].uuid : "",
                name: students[i].name,
                is_account: students[i].is_account,
            } as IStudent
            if (students[i].birth_year && students[i].current_grade) {
                student.grade = students[i].current_grade
                student.birth_year = new Date(students[i].birth_year!, 0, 0, 0, 0, 0, 0).toISOString()
            }
            requests.push({
                student: student,
                session: students[i].session,
                lng: languageCode,
                zone: DateTime.local().zoneName,
            } as IEnrollmentRequest)
        }

        const request: IEnrollmentsRequest = {
            enrollments: requests,
            lng: languageCode,
            zone: DateTime.local().zoneName
        }

        try {
            const result = await enroll(request).unwrap();
            if ("data" in result && result["data"] === true) {
                updateCompletionModalState("SUCCESS")
            } else {
                updateCompletionModalState("ERROR")
                updateErrorDescription(t(result["message"], { ns: "error" }))
            }
        } catch (error) {
            updateCompletionModalState("ERROR")
            updateErrorDescription(t("error_generic", { ns: "error" }))
        }
    }

    return (
        <div className="card-body container">
            <h5 className="card-title text-center pb-1">
                {t('enrollment', { ns: "enrollment" })}
            </h5>

            <div id="stepperForm" className="bs-stepper linear">

                <div className="bs-stepper-header border-bottom" role="tablist" style={{
                    overflowY: "scroll",
                }}>
                    {/* Account Step - Header */}
                    <div id="sh-1" className="step active" data-target="#test-form-1">
                        <button type="button" className="step-trigger" role="tab" id="stepperFormTrigger1" aria-controls="test-form-1" aria-selected="true" disabled={false}>
                            <span className="bs-stepper-circle">
                                <i className="bi bi-person" aria-hidden="true" />
                            </span>
                            <span className="bs-stepper-label">{t('account', { ns: "enrollment" })}</span>
                        </button>
                    </div>
                    <div className="bs-stepper-line"></div>

                    {/* Student Details Step - Header */}
                    <div id="sh-2" className="step" data-target="#test-form-2">
                        <button type="button" className="step-trigger" role="tab" id="stepperFormTrigger2" aria-controls="test-form-2" aria-selected="false" disabled={true}>
                            <span className="bs-stepper-circle">
                                <i className="bi bi-mortarboard" aria-hidden="true" />
                            </span>
                            <span className="bs-stepper-label">{t('student_details', { ns: "enrollment" })}</span>
                        </button>
                    </div>
                    <div className="bs-stepper-line"></div>

                    {/* Class Time Step - Header */}
                    <div id="sh-3" className="step" data-target="#test-form-3">
                        <button type="button" className="step-trigger" role="tab" id="stepperFormTrigger3" aria-controls="test-form-3" aria-selected="false" disabled={true}>
                            <span className="bs-stepper-circle">
                                <i className="bi bi-calendar2" aria-hidden="true" />
                            </span>
                            <span className="bs-stepper-label">{t('class_time', { ns: "enrollment" })}</span>
                        </button>
                    </div>
                    <div className="bs-stepper-line"></div>

                    {/* Payment Information Step - Header */}
                    <div id="sh-4" className="step" data-target="#test-form-4">
                        <button type="button" className="step-trigger" role="tab" id="stepperFormTrigger4" aria-controls="test-form-4" aria-selected="false" disabled={true}>
                            <span className="bs-stepper-circle">
                                <i className="bi bi-credit-card" aria-hidden="true" />
                            </span>
                            <span className="bs-stepper-label">{t('payment', { ns: "enrollment" })}</span>
                        </button>
                    </div>
                    <div className="bs-stepper-line"></div>

                    {/* Finished Step - Header */}
                    <div id="sh-5" className="step" data-target="#test-form-5">
                        <button type="button" className="step-trigger" role="tab" id="stepperFormTrigger3" aria-controls="test-form-3" aria-selected="false" disabled={true}>
                            <span className="bs-stepper-circle">
                                <i className="bi bi-check-square" aria-hidden="true" />
                            </span>
                            <span className="bs-stepper-label">{t('submit', { ns: "enrollment" })}</span>
                        </button>
                    </div>
                </div>

                <div className="bs-stepper-content py-3">
                    <form>
                        {/* Account Step */}
                        <div id="test-form-1" role="tabpanel" className="bs-stepper-pane fade active dstepper-block" aria-labelledby="stepperFormTrigger1">
                            <Authenticate view="SIGNUP" fadeBackground={false} />
                        </div>

                        {/* Student Details */}
                        <div id="test-form-2" role="tabpanel" className="bs-stepper-pane fade dstepper-none" aria-labelledby="stepperFormTrigger2">
                            <div className="container col-lg-7 col px-4">
                                <div className="fs-5 text-center">
                                    {props.offering.course.age_min && t('enroll_details_or_children', { ns: "enrollment" })}
                                    {!props.offering.course.age_min && t('enroll_details_or_other', { ns: "enrollment" })}
                                </div>
                                <div className="row py-4 px-2">
                                    <button type="button" className=" col-5 btn btn-outline-dark" onClick={enrollMyself}>
                                        {t('enroll_acc_details', { ns: "enrollment" })}
                                    </button>
                                    <div className="col-2 align-self-center p-1 text-faded text-center">or</div>
                                    <button type="button" className={"col-5 btn " + (showEnrollChildren ? "btn-dark" : "btn-outline-dark")} onClick={enrollChildren}>
                                        {props.offering.course.age_min && <div className="align-self-center">{t('enroll_children', { ns: "enrollment" })}</div>}
                                        {!props.offering.course.age_min && <div className="align-self-center">{t('enroll_other', { ns: "enrollment" })}</div>}
                                    </button>
                                    {showEnrollChildren && <div className="border-top mt-3 fadeIn pt-3 px-0 text-center fadeIn">
                                        <div className="card-text text-center">
                                            {props.offering.course.age_min && t('add_children', {ns: "enrollment" })}
                                            {!props.offering.course.age_min && t('add_students', {ns: "enrollment" })}
                                        </div>
                                        <div className="my-2 d-flex flex-column align-items-center">
                                            {students.map((student, index) =>
                                                <div key={"student-" + index} className="d-flex justify-content-between rounded border border-2 my-2 fadeIn col-12 col-md-10">
                                                    <div className="card-text text-left align-self-center h6 mx-3 mb-0">
                                                        {student.name} ({String(student.birth_year)}) - {student.current_grade}
                                                    </div>
                                                    <div className="d-flex">
                                                        {!student.uuid && <button type="button" className="btn btn-secondary rounded-0" onClick={() => updateSelectedStudent(index)}
                                                            data-bs-toggle={StudentDetailModalHooks.MODAL_DATA_BS_TOGGLE} data-bs-target={StudentDetailModalHooks.MODAL_DATA_BS_TARGET}>
                                                            <i className="bi bi-pencil h4"></i>
                                                        </button>}
                                                        <button type="button" className="btn btn-danger rounded-0 rounded-end" onClick={() => { removeStudent(index) }}>
                                                            <i className="bi bi-trash h4"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row justify-content-center">
                                            <div className="col-md-5 col-8 ">
                                                {/* Display Button */}
                                                <button type="button" className="btn btn-primary mt-1 w-100" onClick={() => updateSelectedStudent(-1)}
                                                    data-bs-toggle={StudentDetailModalHooks.MODAL_DATA_BS_TOGGLE} data-bs-target={StudentDetailModalHooks.MODAL_DATA_BS_TARGET}>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div className="mx-1"><i className="bi bi-plus h4"></i></div>
                                                        <div className="text-center align-self-center mx-1">{t('add_student', { ns: "enrollment" })}</div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                        <StudentDetailsModal studentIndex={selectedStudent} userDetails={userData} />
                                    </div>}
                                </div>
                            </div>
                        </div>

                        {/* Class Time */}
                        <div id="test-form-3" role="tabpanel" className="bs-stepper-pane fade dstepper-none" aria-labelledby="stepperFormTrigger3">
                            <div className="d-flex flex-column align-items-center">
                                <div className="col col-md-7 mb-3">
                                    <div className="card-text h5 text-center">
                                        {t('select_weekly_class_time', { ns: "enrollment" })}
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-borderless align-middle text-center border border-2">
                                            <thead className="table-light">
                                                <tr>
                                                    {DAYS_OF_WEEK.map((day, index) =>
                                                        <th key={index} scope="col" className="">{day}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.offering.sessions.length > 0 && generateCalendar().map((hourSet, hourOffset) =>
                                                    <tr key={hourOffset}>
                                                        {hourSet.map((calendarItem, index) =>
                                                            <td key={index} className="py-2 px-0">
                                                                <button type="button"
                                                                    id={"availability-" + calendarItem.dateTime.weekday + "-" + calendarItem.dateTime.hour}
                                                                    className={"btn btn-sm rounded-0 " + getCalendarButtonClass(calendarItem)}
                                                                    disabled={!isAvailable(calendarItem)}
                                                                    onClick={() => { selectTime(calendarItem) }}
                                                                >
                                                                    {calendarItem.dateTime.toLocal().setLocale('en').toFormat("h a")}
                                                                </button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                )}
                                                {props.offering.sessions.length == 0 && <td colSpan={DAYS_OF_WEEK.length} className="py-5">
                                                    <div className="row row-cols-1">
                                                        <div className="col text-center">
                                                            <div className="fw-bolder fs-5 mb-5">
                                                                {t('no_classes', { ns: "enrollment" })}

                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <SubscribeCard prompt={t('no_classes_prompt', { ns: "enrollment" })} target={"NEW_SESSION_FOR_OFFERING_" + props.offering.id} />
                                                        </div>
                                                    </div>
                                                </td>}
                                                <tr>
                                                    <td colSpan={DAYS_OF_WEEK.length} className="table-light">
                                                        <div className="card-text">
                                                            <div className="fw-bold">{t('timezone', { ns: "enrollment" })} {TIME_RANGE.starting_point.toLocal().setLocale(languageCode).toFormat("z")}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Case For One Child */}
                                    {students.length == 1 &&
                                        <div className="text-center h5">
                                            {students[0].class_time !== undefined &&
                                                (t('selected_time', { ns: "enrollment" }) + " " + DateTime.fromISO(students[0].class_time).toLocal().setLocale(languageCode).toFormat("cccc h") + " " + DateTime.fromISO(students[0].class_time).toLocal().setLocale('en').toFormat("a"))
                                            }
                                            {students[0].class_time === undefined &&
                                                t('no_time_selected', { ns: "enrollment" })
                                            }
                                        </div>
                                    }
                                    {/* Case for 2+ Children */}
                                    {students.length > 1 &&
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-10 text-center">
                                                    {t('select_time_instructions', { ns: "enrollment" })}
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {students.map((student, index) => <>
                                                        <input onClick={() => { selectStudent(index) }} type="radio" className="btn-check" name="classTimeSelectStudentRadio" id={"classTimeList-" + index} autoComplete="off" defaultChecked={index === selectedStudent || (index === 0 && selectedStudent == -1)}></input>

                                                        <label htmlFor={"classTimeList-" + index} className="w-100 mt-3 d-flex justify-content-between btn btn-outline-primary m-0 p-1">
                                                            <div className="align-self-center m-2">
                                                                {student.name}
                                                            </div>
                                                            <div className="fw-semibold mx-4 align-self-center ">
                                                                {student.class_time !== undefined &&
                                                                    <>
                                                                        {DateTime.fromISO(student.class_time).toLocal().setLocale(languageCode).toFormat("cccc h") + " " + DateTime.fromISO(student.class_time).toLocal().setLocale('en').toFormat("a")}
                                                                    </>

                                                                }
                                                                {student.class_time === undefined &&
                                                                    t('no_time_selected', { ns: "enrollment" })
                                                                }
                                                            </div>
                                                        </label>
                                                    </>)}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* Payment Information */}
                        <div id="test-form-4" role="tabpanel" className="bs-stepper-pane fade dstepper-none" aria-labelledby="stepperFormTrigger4">
                            <div className="d-flex flex-column align-items-center">
                                <div className="col col-md-7 mb-3">
                                    <div className="card-title h4 text-center mb-3">
                                        {t('payment_information', { ns: "enrollment" })}
                                    </div>
                                    <div className="card-text text-center">
                                        {t('payment_information_description', { ns: "enrollment" })}
                                    </div>
                                    <div className="border border-3 border-top-0 border-left-0 border-right-0">
                                        <div className="h6 mt-3">
                                            {t('enrollment_breakdown', { ns: "enrollment" })}
                                        </div>
                                        {students.map((student) =>
                                            <div className="d-flex justify-content-between fw-light pb-2">
                                                <div className="pl-3 mx-3">{student.name}</div>
                                                <div className="text-success">
                                                    ₩{(props.offering.course.price * 1).toLocaleString()}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2 h5">
                                        <div className="">{t('total_cost', { ns: "enrollment" })}</div>
                                        <div className="text-success">
                                            ₩{(props.offering.course.price * students.length).toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Summary */}
                        <div id="test-form-5" role="tabpanel" className="bs-stepper-pane fade text-center dstepper-none" aria-labelledby="stepperFormTrigger5">
                            <div className="card-title h4 text-center mb-3">
                                {t('review_enrollment_details', { ns: "enrollment" })}
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 border-md-right border-bottom border-md-bottom-0 mb-3">
                                    <div className="card">
                                        <div className="card-header fw-semibold">
                                            {t('course_information', { ns: "enrollment" })}
                                        </div>
                                        <div className="card-body">
                                            <div className="text-start d-flex flex-wrap align-items-start 
                                        justify-content-start row-cols-2 container">


                                                <div className="mb-3">{t('course_name', { ns: "course" })}:</div>
                                                <div className="mb-3"><Translation ns={`course_` + props.offering.course.id}>{(t) => <>{t('course_name')}</>}</Translation></div>

                                                <div className="mb-3">{t('location', { ns: "course" })}:</div>
                                                <div className="mb-3"><Translation ns={`course_` + props.offering.course.id}>{(t) => <>{t('course_mode')}</>}</Translation></div>

                                                <div className="mb-3">{t('duration', { ns: "course" })}:</div>
                                                <div className="mb-3">
                                                    {t('course_duration_week', { ns: "course", weeks: props.offering.course.duration })}
                                                    <br />
                                                    {t('course_duration_hour', { ns: "course", hour: props.offering.course.weekly_hours })}
                                                </div>

                                                <div className="col">{t('start_week', { ns: "course" })}:</div>
                                                <div className="col">{DateTime.fromISO(props.offering.start_week).setLocale(languageCode).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <div className="card">
                                        <div className="card-header fw-semibold">
                                            {t('enrollment_details', { ns: "enrollment" })}
                                        </div>
                                        <div className="card-body">
                                            <div className="pb-1 border-bottom">
                                                <div className="card-title text-start fw-bold fs-6">{t('student_list', { ns: "enrollment" })}</div>
                                                {students.map((student) =>
                                                    <div className="text-start mx-3 my-3">
                                                        {student.birth_year && <div className="card-text">{student.name + " (" + student.birth_year + ") - " + student.current_grade}</div>}
                                                        {!student.birth_year && <div className="card-text">{student.name}</div>}
                                                        <div className="card-text">
                                                            <span className="">{t('class_time_list', { ns: "enrollment" })} </span>
                                                            <span className="text-success">
                                                                {DateTime.fromISO(student.class_time!).toLocal().setLocale(languageCode).toFormat("cccc h") + " " + DateTime.fromISO(student.class_time!).toLocal().setLocale('en').toFormat("a")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="card-title text-start fw-bold fs-6 my-2">{t('price', { ns: "enrollment" })}</div>
                                            <div className="text-start mx-3 my-2">
                                                <span className="fw-semibold">{t('total_cost', { ns: "enrollment" })}  </span>
                                                <span className="text-success">₩{(props.offering.course.price * students.length).toLocaleString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Progress Buttons */}
            {showStepperButtons && <div className="mb-2 container fadeIn d-flex justify-content-center">
                <div className="btn-group col col-lg-7" role="group" aria-label="Basic mixed styles example">
                    {currentStep > 2 && <button type="button" className="btn btn-outline-primary col-6" onClick={previousStep}>{t('previous', { ns: "enrollment" })}</button>}
                    <button type="button" className={"btn col-6 " + (currentStep == 5 ? "btn-success" : "btn-outline-primary")} onClick={nextStep}
                        disabled={disableNextButton}
                        data-bs-toggle={currentStep == 5 ? "modal" : ""} data-bs-target={currentStep == 5 ? "#registrationCompleteModal" : ""}
                    >
                        {currentStep == 5 ? t('enroll_now', { ns: "enrollment" }) : t('next', { ns: "enrollment" })}
                    </button>
                </div>
            </div>}
            {completionModalState === "SUCCESS" && <div className="mb-2 container fadeIn d-flex justify-content-center">
                <div className="btn-group col col-lg-7" role="group" aria-label="Basic mixed styles example">
                    <button type="button" className="btn btn-outline-primary col-6" onClick={() => { navigate("/" + languageCode + "/account/enrollments") }}>
                        {t('view_my_enrollments', { ns: "enrollment" })}
                    </button>
                </div>
            </div>}

            {/* Complete Modal */}
            <div className="modal fade " id="registrationCompleteModal" tabIndex={-1} aria-labelledby="Registration Complete" aria-hidden="true" data-backdrop="static" >
                {/** Loading */}
                {completionModalState === "LOADING" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t('enrolling', { ns: "validate" })}</h5>
                        </div>
                        <div className="modal-body text-center">
                            <div className="spinner-border text-primary m-4" role="status">
                                <span className="visually-hidden">{t('loading', { ns: "validate" })}</span>
                            </div>
                        </div>
                    </div>
                </div>}

                {/** Success */}
                {completionModalState == "SUCCESS" && <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('enrollment_successful', { ns: "enrollment" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center fs-5">{t('congratulations', { ns: "enrollment" })} </div>
                            <div className="card-text text-center my-2">{t('enrollment_success_details', { ns: "enrollment" })}</div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">{t('close', { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}

                {/** Error */}
                {completionModalState === "ERROR" && <div className="modal-dialog modal-dialog-centered fadeIn">
                    <div className="modal-content">
                        <div className="modal-header justify-content-centered">
                            <h5 className="modal-title" id="submittedModalLabel">{t('whoops', { ns: "validate" })}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h1 className='text-center'>❌</h1>
                            <p className='text-center h6'>{errorDescription} </p>
                        </div>
                        <div className="container mb-3">
                            <button type="button" className="btn btn-secondary center w-100" data-bs-dismiss="modal">{t('close', { ns: "validate" })}</button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default CourseRegistration;


