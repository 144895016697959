import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Landing = () => {
    const { t } = useTranslation();
    const languageCode = i18next.language;

    return (
        <section
            className="bg-dark text-light p-5 pt-lg-5 text-center text-sm-start bg-image"
            style={{
                backgroundImage: "url(/img/student_learning.jpg)",
                backgroundSize: "cover",
                maxWidth: "100%",
                minHeight: "50vh",
                height: "auto",
            }}
        >
            <div className="container"
            >
                <div className="d-sm-flex align-items-center justify-content-between">
                    <div className="bg-opacity-50 bg-black p-2 p-sm-3 rounded">
                        <h1>{t('landing_title_0',  { ns: 'home' })} <span className="text-warning"> {t('landing_title_1',  { ns: 'home' })} </span> {t('landing_title_2',  { ns: 'home' })}</h1>
                        <p className="lead my-4">
                            {t('landing_subheading', { ns: "home" })}
                        </p>
                        <NavLink to={"/" + languageCode + "/courses"} className="nav-link">
                            <button
                                className="btn btn-primary btn-lg"
                            >
                                {t('enroll_now')}
                            </button>
                        </NavLink>
                    </div>
                    <img
                        className="img-fluid w-100 d-none d-sm-block"
                        src=""
                        alt=""
                    />
                </div>
            </div>
        </section>
    )
}

export default Landing;