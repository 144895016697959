import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IEnrollment } from '../../interface/user';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import EnrollmentCard from './EnrollmentCard';
import { useNavigate } from 'react-router-dom';

interface props {
    enrollments?: IEnrollment[]
}

const EnrollmentDetails = (props: props) => {
    const { t } = useTranslation();
    const [nsIsLoading, updateNSIsLoading] = useState(true)
    const [isLoading, updateIsLoading] = useState(true)
    const navigate = useNavigate()
    const languageCode = i18next.language;

    useEffect(() => {
        i18next.loadNamespaces('enrollment_details').then(() => { updateNSIsLoading(false) });
    })

    useEffect(() => {
        if (props.enrollments && !nsIsLoading) {
            updateIsLoading(false)
        } else {
            updateIsLoading(true)
        }
    }, [props, nsIsLoading])


    return (
        <>
            {!isLoading && <div className="container fadeIn">
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <p className="h4 border-bottom pb-2 mb-2">{t('title', { ns: "enrollment_details" })}</p>
                    <div className="row row-cols-1 row-cols-lg-2">
                        {props.enrollments?.length! > 0 && <>
                            {props.enrollments?.map((enrollment, index) =>
                                <div key={index} className="col col-lg-6 my-2">
                                    <EnrollmentCard enrollment={enrollment} />
                                </div>
                            )}</>
                        }
                        {props.enrollments?.length! == 0 &&
                            <>
                                <div className="col col-xl-5 my-2 my-xl-0 d-flex align-items-center justify-content-center">
                                    <p className="h4 px-3 m-0 text-center">
                                        {t("no_students", { ns: "student_details" })}
                                    </p>
                                </div>
                                <div className="col col-xl-3 my-2 my-xl-0">
                                    <button type="button" className="btn btn-success b-3 w-100
                                                d-inline-flex flex-row justify-content-center align-items-center"
                                        onClick={() => { navigate("/" + languageCode + "/courses") }}>
                                        <div className='p-1'>
                                            <i className="h3 bi bi-ui-checks"></i>
                                        </div>
                                        <div className='fs-5 text-center p-1'>
                                            {t("enroll_now", { ns: "student_details" })}
                                        </div>
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>

            </div>}
            {isLoading && <div className="container fadeIn">
                <div className="my-3 p-3 bg-body rounded shadow-sm placeholder-glow">
                    <p className="h4 border-bottom pb-2 mb-2 placeholder col-4"></p>
                    <div className="row row-cols-1 row-cols-lg-2">
                        <div className="col col-lg-6 my-2">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title h5 text-center placeholder col-12"></div>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item ">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                    <li className="list-group-item">
                                        <ul className="list-inline lead fs-6">
                                            <li className="list-inline-item col-4 placeholder"></li>
                                            <li className="list-inline-item col-7 placeholder"></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>}
        </>
    );
};

export default EnrollmentDetails;