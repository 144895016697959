import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import viewPortSlice from './reducers/viewPortSlice';
import courseSlice from './reducers/courseSlice';

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { BrowserRouter } from 'react-router-dom';


// Bootstrap CSS
//import "bootstrap/dist/css/bootstrap.min.css";
//import "../src/style/css/global.min.css";
import "../src/style/global.scss";
import "../src/style/globalCustom.css";

// Bootstrap Bundle JS
//import "bootstrap/dist/js/bootstrap.bundle.min";

// Bootstap icons
import 'bootstrap-icons/font/bootstrap-icons.css';

import App from './App';
import LanguageRedirect from './components/panels/LanguageRedirect';
import authSlice from './reducers/authSlice';
import { apiSlice } from './api/apiSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
    viewPort: viewPortSlice,
    course: courseSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
});

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ko'],
    ns: ['default', 'home', "course", "instructor", "faq", 'error', 'validate', 'enrollment', 'about_us'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'navigator'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
  })

const loadingMarkup = (
  <div
    style={{
      width: "100vw",
      height: "100vh",
    }}
    className="d-flex justify-content-center justify-content-center">
    <div style={{
      width: "7rem",
      height: "7rem",
    }}
      className="spinner-border text-primary align-self-center" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={loadingMarkup}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default loadingMarkup

