import { createSlice, current } from "@reduxjs/toolkit"
import { IAuthentication } from "../interface/apiRequests"
import { RootState } from ".."

const initialState: IAuthentication = {
    uuid: null,
    token: null,
    roles: null,
    name: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCredentials(state, action) {
            const { uuid, accessToken, roles, name } = action.payload
            const newState: IAuthentication = {
                uuid: uuid,
                token: accessToken,
                roles: roles,
                name: name
            }
            return newState
        },
        setAccessToken(state, action) {
            state.token = action.payload
        },
        setCredentialsLogout(state) {
            return initialState
        }
    },
})

export const { setCredentials, setAccessToken, setCredentialsLogout } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.uuid
export const selectCurrentToken = (state: RootState) => state.auth.token
export const selectCurrentRoles = (state: RootState) => state.auth.roles