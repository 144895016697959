import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { Translation, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ICourse } from '../../interface/course'
import { IInstructor } from '../../interface/instructor'
interface CourseCardProps {
    course: ICourse,
}
const CourseCard = (props: CourseCardProps) => {
    var { t } = useTranslation();
    const languageCode = i18next.language;
    const [isLoading, updateIsLoading] = useState(true)

    var nameSpace = 'course_' + props.course.id

    useEffect(() => {
        i18next.loadNamespaces([nameSpace]).then(() => { updateIsLoading(false) });
    }, [nameSpace])

    function getCourseLevel(level: string) {
        switch (level) {
            case 'Intermediate':
                return t('level_intermediate', { ns: "course" })
            case 'Advanced':
                return t('level_advanced', { ns: "course" })
            case 'Expert':
                return t('level_expert', { ns: "course" })
            default:
                return t('level_beginner', { ns: "course" })
        }
    }

    return (
        <div className="col col-lg-6 col-xl-5">
            {!isLoading && <div className="card mb-3">
                <div className="row g-0">
                    <div className="col-md-3" style={{ overflow: "hidden" }}>
                        <img src={"/img/" + props.course.image_path} className="img-fluid rounded-top"
                            style={{
                                objectFit: "cover",
                                minHeight: "100%",
                                minWidth: "100%",
                                maxHeight: "15vh"
                            }} />
                    </div>
                    <div className="col-md-9">
                        <div className="card-body">
                            <h5 className="card-title">
                                <Translation ns={nameSpace}>
                                    {(t) => <>{t('course_name')}</>}
                                </Translation>
                            </h5>
                            <p className="card-text">
                                <Translation ns={nameSpace}>
                                    {(t) => <>{t('course_short')}</>}
                                </Translation>
                            </p>
                            <div className="fw-semibold">
                                <div className="row text-start">
                                    <div className="col-5">
                                        {props.course.age_min && <div className="mb-1 text-truncate">{t('age', { ns: "course" })}:</div>}
                                        <div className="mb-1">{t('level', { ns: "course" })}:</div>
                                        <div className="mb-1">{t('duration', { ns: "course" })}:</div>
                                        {/*<div className="mb-1">{t('total_price', { ns: "course" })}:</div> */}
                                    </div>
                                    <div className="col-6">
                                        {props.course.age_min && <div className="mb-1">{t('course_age', { ns: "course", min_age: props.course.age_min, max_age: props.course.age_max })}</div>}
                                        <div className="mb-1 text-success">{getCourseLevel(props.course.difficulty_level)}</div>
                                        <div className="mb-1">
                                            {t('course_duration_week', { ns: "course", weeks: props.course.duration })}
                                            {t('course_duration_hour_short', { ns: "course", hour: props.course.weekly_hours })}
                                        </div>
                                        {/*<div className="mb-1 text-danger"> {t('course_price', { ns: "course", price: (props.course.price / 1).toLocaleString() })} <s>{t('course_price', { ns: "course", price: (props.course.price / 1 + 201000).toLocaleString() })}</s></div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={props.course.offerings ? "/" + languageCode + "/offering/" + props.course.offerings[0].id : ""} className="d-grid">
                    <button className="btn btn-primary rounded-0 rounded-bottom">
                        {t('open', { ns: "course" })}
                    </button>
                </Link>
            </div>}
            {isLoading && <div className="card mb-3">
                <div className="row g-0">
                    <div className="col-md-3" style={{ overflow: "hidden", backgroundColor: "lightgrey" }}>

                    </div>
                    <div className="col-md-9">
                        <div className="card-body placeholder-glow">
                            <h5 className="card-title placeholder col-10">
                            </h5>
                            <span className="placeholder col-8">
                            </span>
                            <span className="placeholder col-8 mb-2">
                            </span>
                            <div className="fw-semibold">
                                <div className="row text-start">
                                    <div className="col-5">
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                    </div>
                                    <div className="col-6">
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                        <span className="placeholder col-8" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary rounded-0 rounded-bottom disabled placeholder">

                </button>
            </div>}
        </div>
    )
}

export default CourseCard
