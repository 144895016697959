import { useTranslation } from 'react-i18next';
import { useCoursesQuery } from '../../api/courseApiSlice';
import { ICourse } from '../../interface/course';
import { IInstructor } from '../../interface/instructor';
import SubscribeCard from '../utils/SubscribeCard';
import CourseCard from './CourseCard';

const CourseList = () => {
    const CATEGORIES = ['Primary', 'Middle', 'High', 'University']

    var { t } = useTranslation();
    var courses: ICourse[] | null = null

    const { data, error, isLoading } = useCoursesQuery({})

    if (error) {
        // TODO: Show error message
    }

    if (!isLoading && data) {
        try {
            const temp: ICourse[] = Object.values(data["data"] as ICourse[])
            courses = temp
        } catch (error) {
            courses = null
        }
    }

    function getCourses(category: string) {
        var coursesReturn: ICourse[] = []
        if (courses) {
            coursesReturn = courses.filter((course) => { return course.category == category || (category == "University" && course.category == "Adult") })
        }
        return coursesReturn
    }

    return (
        <div className="bg-light min-vh-100">
            <section className="p-3 container">
                {/* Course List Title */}
                <div className="d-flex align-items-center justify-content-between 
                                p-3 my-3 text-muted border-secondary shadow-sm rounded-2"
                    style={{
                        backgroundColor: "var(--bs-purple)",
                    }}>
                    <i className="bi bi-laptop h1 text-white"></i>
                    <h1 className="h3 mb-0 text-light lh-1">{t('course_list', { ns: "course" })}</h1>
                    <i className="bi bi-laptop h1 text-white"></i>
                </div>

                {!courses &&
                    <div className="my-3 p-3 bg-body rounded shadow-sm placeholder-glow" >
                        <p className="h5 border-bottom mb-0 placeholder col-5"></p>
                        <div className="d-flex flex-row g-3 text-muted pt-3">
                            <div className="col col-lg-6 col-xl-5">
                                <div className="card mb-3">
                                    <div className="row g-0">
                                        <div className="col-md-3" style={{ overflow: "hidden", backgroundColor: "lightgrey" }}>

                                        </div>
                                        <div className="col-md-9">
                                            <div className="card-body placeholder-glow">
                                                <h5 className="card-title placeholder col-10">
                                                </h5>
                                                <span className="placeholder col-8">
                                                </span>
                                                <span className="placeholder col-8 mb-2">
                                                </span>
                                                <div className="fw-semibold">
                                                    <div className="row text-start">
                                                        <div className="col-5">
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                            <span className="placeholder col-8" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary rounded-0 rounded-bottom disabled placeholder">

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                } {courses &&
                    <>
                        {CATEGORIES.map((category, index) =>
                            <div className="my-3 p-3 bg-body rounded shadow-sm" key={index}>
                                <p className="h5 border-bottom pb-2 mb-0">{t(category, { ns: 'course' })}</p>
                                <div className="d-flex flex-row g-3 text-muted pt-3">
                                    <>
                                        {
                                            getCourses(category).length > 0 &&
                                            getCourses(category).map((course, courseIndex) =>
                                                <CourseCard course={course} key={courseIndex} />
                                            )
                                        }{
                                            getCourses(category).length == 0 &&
                                            <div className="text-center w-100">
                                                <div className="h3">{t('course_comming_soon', { ns: "course" })}</div>
                                            </div>
                                        }
                                    </>
                                </div>
                                {false &&
                                    <small className="d-block text-end">
                                        <a href="#">{t('view_more', { ns: 'course' })}</a>
                                    </small>
                                }
                            </div>
                        )}
                        <div className="my-3 p-3 bg-body rounded shadow-sm" key={-1}>
                            <p className="h3 text-center border-bottom pb-2 mb-0">{t('no_course_for_you', { ns: "course" })}</p>
                            <div className="d-flex flex-row g-3 text-muted pt-3">
                                <div className="col container">
                                    <SubscribeCard prompt={t('course_notified_prompt', { ns: "course" })} target={"NEW_COURSE"} />
                                </div>
                            </div>
                        </div>
                    </>
                }


            </section>
        </div>
    )
}

export default CourseList;