import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IStudent } from '../../interface/user';

interface props {
    student: IStudent
}

const StudentCard = (props: props) => {
    const { t } = useTranslation();
    const [isLoading, updateIsLoading] = useState(true)

    useEffect(() => {
        i18next.loadNamespaces('student_details').then(() => { updateIsLoading(false) });
    })

    return (
        <>
            {!isLoading && <div className="card">
                <div className="card-header">
                    <div className="card-title h5 mb-0 text-center">{props.student.name}</div>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('name', { ns: "student_details" })}:</strong></li>
                            <li className="list-inline-item col-7">{props.student.name}</li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4"><strong>{t('grade', { ns: "student_details" })}:</strong></li>
                            <li className="list-inline-item col-7">
                                { props.student.is_account? "-" : props.student.grade }
                            </li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 "><strong>{t('birth_year', { ns: "student_details" })}:</strong></li>
                            <li className="list-inline-item col-7">
                                { props.student.is_account? "-" : (new Date(String(props.student.birth_year))).getFullYear() + 1 }
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>}
            {isLoading && <div className="card placeholder-glow">
                <div className="card-header text-left">
                    <div className="card-title h5 mb-0 placeholder col-11"></div>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                    <li className="list-group-item">
                        <ul className="list-inline lead fs-6">
                            <li className="list-inline-item col-4 placeholder"></li>
                            <li className="list-inline-item col-7 placeholder"></li>
                        </ul>
                    </li>
                </ul>
            </div>}
        </>
    )
}

export default StudentCard;