import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setCourseList } from '../../reducers/viewPortSlice';

const ThreeCard = () => {
    const { t } = useTranslation();
    const languageCode = i18next.language;


    return (
        <section className="p-3">
            <h1 className='text-center p-3'>{t('why_learn_with_us', { ns: 'home' })}</h1>
            <div className="container">
                <div className="row text-center g-3">
                    <div className="col-md">
                        <div className="card bg-primary bg-opacity-75 text-light">
                            <div className="card-body text-center">
                                <div className="h1 mb-3">
                                    <i className="bi bi-person-workspace"></i>
                                </div>
                                <h3 className="card-title mb-3">{
                                    t('expert_intructors_title', { ns: 'home' })}
                                </h3>
                                <p className="card-text">
                                    {t('expert_intructors_description', { ns: 'home' })}
                                </p>
                                <NavLink to={"/" + languageCode + "/instructors"} >
                                    <a className="btn btn-outline-light">{
                                        t('expert_intructors_button', { ns: 'home' })}
                                    </a>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="card bg-light  text-dark">
                            <div className="card-body text-center">
                                <div className="h1 mb-3">
                                    <i className="bi bi-piggy-bank"></i>
                                </div>
                                <h3 className="card-title mb-3">
                                    {t('affordable_price_title', { ns: 'home' })}
                                </h3>
                                <p className="card-text">
                                    {t('affordable_price_description', { ns: 'home' })}
                                </p>
                                <NavLink to={"/" + languageCode + "/courses"} >
                                    <a className="btn btn-outline-dark">
                                        {t('affordable_price_button', { ns: 'home' })}
                                    </a>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="card bg-primary bg-opacity-75 text-light">
                            <div className="card-body text-center">
                                <div className="h1 mb-3">
                                    <i className="bi bi-translate"></i>
                                </div>
                                <h3 className="card-title mb-3">
                                    {t('real_english_title', { ns: 'home' })}
                                </h3>
                                <p className="card-text">
                                    {t('real_english_description', { ns: 'home' })}
                                </p>
                                <NavLink to={"/" + languageCode + "/about"} >
                                    <a className="btn btn-outline-light">
                                        {t('real_english_button', { ns: 'home' })}
                                    </a>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ThreeCard;