import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useOfferingQuery } from '../../api/courseApiSlice';
import { IOffering } from '../../interface/course';
import ContactUsButton from '../utils/ContactUsButton';
import CourseRegistration from './CourseRegistration';
import i18next from 'i18next';
import { Translation, useTranslation } from 'react-i18next';
import InstructorCard from '../instructor/InstructorCard';
import { DateTime } from 'luxon';
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category = "Test Category") => {
    const eventTracker = (action = "Test Action", label = "Test Label") => {
        ReactGA.event({ category, action, label });
    }
    return eventTracker;
}

const CourseDetails = () => {
    var { t } = useTranslation();
    const params = useParams();
    const languageCode = i18next.language;

    const [showEnrollment, updateShowEnrollment] = useState(true);

    const offering_id_pased = parseInt(params.offering_id + "")
    const { data, error, isLoading } = useOfferingQuery(offering_id_pased, { skip: isNaN(offering_id_pased) })
    var nameSpace: string = ""
    var offering: IOffering | null = null

    const gaEventTracker = useAnalyticsEventTracker('Enrollment');

    if (isNaN(offering_id_pased)) {
        return redirect()
    }

    if (!isLoading && data) {
        try {
            const temp: IOffering = data["data"] as IOffering
            nameSpace = 'course_' + temp.course.id
            i18next.loadNamespaces([nameSpace]).then(() => { });
            offering = temp
        } catch (error) {
            offering = null
            return redirect()
        }
    }


    function redirect() {
        return <Navigate replace to="/" />;
    }

    function toggleEnrollment(_event: any) {
        if (showEnrollment) {
            gaEventTracker('Offering Page Button')
        }
        updateShowEnrollment(!showEnrollment);
    }

    function getCourseLevel(level: string) {
        switch (level) {
            case 'Intermediate':
                return t('level_intermediate', { ns: "course" })
            case 'Advanced':
                return t('level_advanced', { ns: "course" })
            case 'Expert':
                return t('level_expert', { ns: "course" })
            default:
                return t('level_beginner', { ns: "course" })
        }
    }

    function getEnglishLevel(level: string) {
        switch (level) {
            case 'Intermediate':
                return t('english_intermediate', { ns: "course" })
            case 'Expert':
                return t('english_expert', { ns: "course" })
            case 'Fluent':
                return t('english_fluent', { ns: "course" })
            default:
                return t('english_basic', { ns: "course" })
        }
    }

    function getStatus(level: string) {
        switch (level) {
            case 'Open':
                return t('status_open', { ns: "course" })
            case 'Full':
                return t('status_full', { ns: "course" })
            case 'Closed':
                return t('status_closed', { ns: "course" })
            default:
                return t('status_not_open', { ns: "course" })
        }
    }

    return (
        <>
            {!offering &&
                <div className="bg-light d-flex align-items-center justify-content-center" style={{ height: "60vh" }}>
                    <div className="spinner-border" style={{ width: "5rem", height: "5rem" }} role="status">
                    </div>
                </div>
            }
            {offering &&
                <section className="bg-light overflow-scroll">


                    {/* Title Pane */}
                    <div className="position-relative" style={{
                        overflow: "hidden",
                        maxHeight: "max(20vh, 30vmin)",
                    }}>
                        <img src={"/img/" + offering.course.image_path} className="img-fluid"
                            style={{
                                objectFit: "cover",
                                minWidth: "100%",
                                height: "auto"
                            }} />
                        <div className='position-absolute p-1 px-2 m-3 bottom-0 col-8 col-sm-6 col-lg-4
                                    bg-secondary rounded bg-opacity-75'>
                            <p className="h2 text-light">
                                <Translation ns={nameSpace}>
                                    {(t) => <>{t('course_name')}</>}
                                </Translation>
                            </p>
                        </div>
                    </div>

                    {/* Course Content */}
                    <div className="container pb-2 mb-5 mt-3 overflow-scroll">
                        {/* Description Card */}
                        <div className="card">
                            <div className="card-body w-fluid text-center lead">
                                <Translation ns={nameSpace}>
                                    {(t) => <>{t('course_short')}</>}
                                </Translation>
                            </div>
                        </div>

                        {/* Details Card */}
                        <div className="card mt-3">
                            <div id="carouselExampleFade" className="carousel slide" data-bs-touch="false">
                                <div className="carousel-inner">
                                    {/* Course Information */}
                                    <div className="carousel-item active ">
                                        <ul className="nav nav-tabs d-flex justify-content-around text-center">
                                            <li className="nav-item flex-fill">
                                                <a className="nav-link active" aria-current="page" data-bs-toggle="tab" href="#details">{t('details', { ns: "course" })}</a>
                                            </li>
                                            <li className="nav-item flex-fill">
                                                <a className="nav-link" data-bs-toggle="tab" href="#english">{t('english_level', { ns: "course" })}</a>
                                            </li>
                                            <li className="nav-item flex-fill">
                                                <a className="nav-link" data-bs-toggle="tab" href="#instructor">{t('instructors', { ns: "course" })}</a>
                                            </li>
                                        </ul>

                                        <div className="card-body tab-content">
                                            {/* Details Tab */}
                                            <div className="tab-pane container active" id="details">
                                                <p className="card-text border-bottom p-2">
                                                    <Translation ns={nameSpace}>
                                                        {(t) => <>{t('course_description')}</>}
                                                    </Translation>
                                                </p>
                                                <div className="row">
                                                    <div className="col-12 col-md-6 border-md-right border-bottom border-md-bottom-0 mb-2">
                                                        <h5 className="card-title text-center pb-1">{t('course_information', { ns: "course" })}</h5>
                                                        <div className="fw-semibold text-start d-flex flex-wrap align-items-start 
                                                    justify-content-start row-cols-2 container">
                                                            {offering.course.age_min && <>
                                                                <div className="mb-3">{t('age', { ns: "course" })}:</div>
                                                                <div className="mb-3">{t('course_age', { ns: "course", min_age: offering.course.age_min, max_age: offering.course.age_max })}</div>
                                                            </>}

                                                            <div className="mb-3">{t('level', { ns: "course" })}:</div>
                                                            <div className="mb-3 text-success">{getCourseLevel(offering.course.difficulty_level)}</div>

                                                            <div className="mb-3">{t('location', { ns: "course" })}:</div>
                                                            <div className="mb-3"><Translation ns={nameSpace}>{(t) => <>{t('course_mode')}</>}</Translation></div>

                                                            <div className="mb-3">{t('class_size', { ns: "course" })}:</div>
                                                            <div className="mb-3">{t('course_size', { ns: "course", min_num: offering.class_min, max_num: offering.class_max })}</div>

                                                            <div className="mb-3">
                                                                <div className="tooltip_c">
                                                                    {t('duration', { ns: "course" })}:
                                                                    <span className="tooltiptext_c">
                                                                        {t('duration_description', { ns: "course" })}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                {t('course_duration_week', { ns: "course", weeks: offering.course.duration })}
                                                                <br />
                                                                {t('course_duration_hour', { ns: "course", hour: offering.course.weekly_hours })}
                                                            </div>

                                                            <div className="mb-3">{t('total_price', { ns: "course" })}:</div>
                                                            <div className="mb-3 text-danger">
                                                                <s>{t('course_price', { ns: "course", price: (offering.course.price / 1 + 201000).toLocaleString() })}</s>
                                                                <br />
                                                                {t('course_price', { ns: "course", price: (offering.course.price / 1).toLocaleString() })}
                                                                <br />
                                                                {t('course_price_hourly', { ns: "course", hourly_price: (offering.course.price / offering.course.duration).toLocaleString() })}</div>

                                                            <div className="mb-3">{t('status', { ns: "course" })}:</div>
                                                            <div className="mb-3 text-success">{getStatus(offering.status)}</div>

                                                            {offering.status == "NotOpen" &&
                                                                <div className="mb-3">{t('registration_date', { ns: "course" })}:</div>}
                                                            {offering.status == "NotOpen" &&
                                                                <div className="mb-3">{DateTime.fromISO(offering.enrollment_open).setLocale(languageCode).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</div>
                                                            }
                                                        </div>
                                                        <div className="fw-semibold text-start d-flex flex-wrap align-items-center rounded
                                                    justify-content-start row-cols-2 container bg-secondary py-1 text-light mb-3">
                                                            <div className="col">{t('start_week', { ns: "course" })}:</div>
                                                            <div className="col">{DateTime.fromISO(offering.start_week).setLocale(languageCode).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-2">
                                                        <h5 className="card-title text-center pb-1">{t('what_will_students_create', { ns: "course" })}</h5>
                                                        <p className="text-center card-text">
                                                            {t('students_create_description', { ns: "course" })}
                                                        </p>
                                                        <div className="ratio ratio-16x9">
                                                            <video poster={"/img/course_" + offering.course.id + "_example_icon.jpg"} controls>
                                                                <source src={"/vid/" + offering.course.description_video_path} />
                                                                <p>
                                                                    {t('browser_html', { ns: "course" })}
                                                                    <a href={"/vid/" + offering.course.description_video_path}>{t('students_create', { ns: "course" })}</a>
                                                                </p>
                                                            </video>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* English Level Tab */}
                                            <div className="tab-pane container" id="english">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 border-md-right border-bottom border-md-bottom-0 mb-2">
                                                        <div className="border-bottom pb-2 mb-2">
                                                            <h5 className="card-title text-center pb-1">{t('prerequisites', { ns: "course" })}:</h5>
                                                            <div className="text-center h6 d-flex align-items-center">
                                                                <p className="card-title col mx-3 py-1">{t('required_english_level', { ns: "course" })}:</p>
                                                                <p className="card-title col mx-3 py-1 text-light bg-success rounded">{getEnglishLevel(offering.course.english_level)}</p>
                                                            </div>
                                                            <div className="card-text mb-2">
                                                                <Translation ns={nameSpace}>
                                                                    {(t) => <>{t('english_prerequisites_1')}</>}
                                                                </Translation>
                                                            </div>
                                                            <div className="card-text">
                                                                <Translation ns={nameSpace}>
                                                                    {(t) => <>{t('english_prerequisites_2')}</>}
                                                                </Translation>
                                                            </div>
                                                        </div>
                                                        <div className='pb-2 mb-2'>
                                                            <h5 className="card-title text-center pb-1">{t('what_will_students_learn', { ns: "course" })}</h5>
                                                            <p className="text-center card-text text-secondary">
                                                                {t('what_will_students_learn_description', { ns: "course" })}
                                                            </p>
                                                            <div className="border rounded border-3">
                                                                <ul className="list-group list-group-horizontal text-center">
                                                                    <li className="list-group-item list-group-item-info col"><Translation ns={nameSpace}>{(t) => <>{t('english_concept_1')}</>}</Translation></li>
                                                                    <li className="list-group-item list-group-item-light col"><Translation ns={nameSpace}>{(t) => <>{t('english_concept_2')}</>}</Translation></li>
                                                                </ul>
                                                                <ul className="list-group list-group-horizontal text-center">
                                                                    <li className="list-group-item list-group-item-light col"><Translation ns={nameSpace}>{(t) => <>{t('english_concept_3')}</>}</Translation></li>
                                                                    <li className="list-group-item list-group-item-info col"><Translation ns={nameSpace}>{(t) => <>{t('english_concept_4')}</>}</Translation></li>
                                                                </ul>
                                                                <ul className="list-group list-group-horizontal text-center">
                                                                    <li className="list-group-item list-group-item-info col"><Translation ns={nameSpace}>{(t) => <>{t('english_concept_5')}</>}</Translation></li>
                                                                    <li className="list-group-item list-group-item-light col"><Translation ns={nameSpace}>{(t) => <>{t('english_concept_6')}</>}</Translation></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-2">
                                                        <h5 className="card-title text-center pb-1">{t('english_level_demonstation', { ns: "course" })}:</h5>
                                                        <p className="text-center card-text">
                                                            {offering.course.age_min && t('english_level_demonstation_description', { ns: "course" })}
                                                            {!offering.course.age_min && t('english_level_demonstation_description_adult', { ns: "course" })}
                                                        </p>
                                                        <div className="ratio ratio-16x9">
                                                            <video poster={"/img/course_" + offering.course.id + "_english_icon.jpg"} controls>
                                                                <source src={"/vid/" + offering.course.english_video_path} />
                                                                <p>
                                                                    {t('browser_html', { ns: "course" })}
                                                                    <a href={"/vid/" + offering.course.english_video_path}>{t('english_demo', { ns: "course" })}</a>
                                                                </p>
                                                            </video>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Instructor Tab */}
                                            <div className="tab-pane " id="instructor">
                                                <p className="h4 text-center border-bottom pb-2 mb-2">
                                                    {t('meet_the_instructor', { ns: "course" })}
                                                </p>
                                                <div className="d-flex align-content-center justify-content-center pt-3">
                                                    {/* Intructor Card */}
                                                    <div className="col col-lg-10">
                                                        <InstructorCard instructor={offering.instructors[0]} key={1} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Registeration */}
                                    <div className="carousel-item container">
                                        <CourseRegistration offering={offering} />
                                    </div>

                                </div>
                            </div>


                        </div>

                        {/* Contact Us Button */}
                        <div className="col-12 mt-3 mb-4 d-flex rounded align-items-center justify-content-center fadeIn">
                            <p className="h5 mb-0 col-7 col-md-4 text-center px-2">
                                {t('questions', { ns: "course" })}
                            </p>
                            <div className="col col-md-3 d-flex align-items-center">
                                <ContactUsButton />
                            </div>
                        </div>
                    </div>

                    {/* Footer Sticky Buttons */}
                    <nav className="navbar navbar-nav fixed-bottom bg-light navbar-nav-scroll">
                        <div className="container my-1">
                            {showEnrollment && <button className="btn btn-primary text-white col h3 p-2" data-bs-target="#carouselExampleFade" data-bs-slide="next" onClick={toggleEnrollment}>
                                <i className="bi bi-phone px-2" />
                                {t('enroll_now', { ns: "course" })}
                            </button>}
                            {!showEnrollment && <button className="btn btn-secondary text-white col h3 p-2" data-bs-target="#carouselExampleFade" data-bs-slide="next" onClick={toggleEnrollment}>
                                <i className="bi bi-mortarboard px-2" />
                                {t('return_course', { ns: "course" })}
                            </button>}
                        </div>
                    </nav>
                </section>}
        </>
    )
}

export default CourseDetails;