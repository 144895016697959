import i18next from 'i18next'
import { Translation, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { IInstructor } from '../../interface/instructor'
interface IntructorCardProps {
    instructor: IInstructor,
    key: number,
    show_prompt?: boolean | undefined
}
const InstructorCard = (props: IntructorCardProps) => {
    var { t } = useTranslation();
    const languageCode = i18next.language;

    var nameSpace = 'instructor_' + props.instructor.uuid
    i18next.loadNamespaces([nameSpace]).then(() => { });

    return (
        <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-3 d-flex align-items-center justify-content-center bg-light">
                    <img src={"/img/" + props.instructor.profile_image} className="img-fluid rounded-start"
                        style={{
                            objectFit: "contain",
                            minWidth: "100%",
                            maxHeight: "min(25vh, 35vw)",
                        }} />
                </div>
                <div className="col-md-9">
                    <div className="card-body">
                        <div className="mb-2 pb-1 d-flex flex-column align-items-center align-items-md-start border-bottom">
                            <h5 className="card-title mb-0">
                                <Translation ns={nameSpace}>
                                    {(t) => <>{t('name')}</>}
                                </Translation>
                            </h5>
                            <small className="text-muted">
                                <Translation ns={nameSpace}>
                                    {(t) => <>{t('location')}</>}
                                </Translation>
                            </small>
                        </div>
                        <div className="card-text pb-2 border-bottom mb-2">
                            <Translation ns={nameSpace}>
                                {(t) => <>{t('about_me')}</>}
                            </Translation>
                        </div>

                        <div className="col-12 col-lg-9 text-center text-md-start">
                            <div className="border border-md-all-0 rounded row row-cols-1 row-cols-md-2 mb-2 g-0">
                                <div className="col col-md-4 p-1 border-bottom border-md-bottom-0 fw-semibold text-white 
                                                                                            text-md-black bg-primary bg-md-transparent opacity-75 opacity-md-100">{t("nationality", { ns: "instructor" })}</div>
                                <div className="col p-1">{t(props.instructor.nationality, { ns: "instructor" })}</div>
                            </div>

                            <div className="border border-md-all-0 rounded row row-cols-1 row-cols-md-2 mb-2 g-0">
                                <div className="col col-md-4 p-1 border-bottom border-md-bottom-0 fw-semibold text-white 
                                                                                            text-md-black bg-primary bg-md-transparent opacity-75 opacity-md-100">{t("teaching_experience", { ns: "instructor" })}</div>
                                <div className="col p-1">{t('number_of_years', { ns: "instructor", years: props.instructor.teaching_experience })}</div>
                            </div>

                            <div className="border border-md-all-0 rounded row row-cols-1 row-cols-md-2 mb-2 g-0">
                                <div className="col col-md-4 p-1 border-bottom border-md-bottom-0 fw-semibold text-white 
                                                                                            text-md-black bg-primary bg-md-transparent opacity-75 opacity-md-100">
                                    {t('qualifications', { ns: "instructor" })}
                                </div>
                                <div className="col p-1">
                                    <Translation ns={nameSpace}>
                                        {(t) => <>{t('qualifications_1')}</>}
                                    </Translation><br />
                                    <Translation ns={nameSpace}>
                                        {(t) => <>{t('qualifications_2')}</>}
                                    </Translation><br />
                                    <Translation ns={nameSpace}>
                                        {(t) => <>{t('qualifications_3')}</>}
                                    </Translation><br />
                                    <Translation ns={nameSpace}>
                                        {(t) => <>{t('qualifications_4')}</>}
                                    </Translation><br />
                                    <Translation ns={nameSpace}>
                                        {(t) => <>{t('qualifications_5')}</>}
                                    </Translation><br />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {props.show_prompt == true &&
                <NavLink to={"/" + languageCode + "/courses"} >
                    <Translation ns={nameSpace}>
                        {(s) => <a className="w-100 btn btn-success text-center btn-lg rounded-0 rounded-bottom">
                            {t('view_courses', { ns: 'instructor', name:s('name') })}
                        </a>}
                    </Translation>
                </NavLink>
            }
        </div >
    )
}

export default InstructorCard