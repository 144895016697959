import { createSlice } from "@reduxjs/toolkit";

export const viewPort = createSlice({
    name: "viewPort",
    initialState: {
        value: "COURSES_DETAILS"
    },
    reducers: {
        setHomePage: state => {
            state.value = "HOME_PAGE"
        },
        setCourseList: state => {
            state.value = "COURSES_LIST"
        },
        setCourseDetails: state => {
            state.value = "COURSES_DETAILS"
        },
    },
});

export const { setHomePage, setCourseList, setCourseDetails } = viewPort.actions

export default viewPort.reducer;