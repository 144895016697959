import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import { useLogoutMutation } from '../../api/authApiSlice';
import { useEffect } from 'react';
import { selectCurrentToken, setCredentialsLogout } from '../../reducers/authSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import { RootState, store } from '../..';

const Navbar = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const auth = useAppSelector(state => state.auth.uuid);

    const languageCode = i18next.language;
    const LANGUAGES = [['en', "English"], ['ko', '한국어']]

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const [
        logoutMutation, // This is the mutation trigger
        { isLoading: isUpdating }, // This is the destructured mutation result
    ] = useLogoutMutation()


    async function logout_option() {
        logoutMutation({})
        dispatch(setCredentialsLogout())
    }

    function generateLanguageButton(screenSize: boolean) {
        return (
            <div className={'d-flex align-items-center mt-0 mt-lg-2 ' + (screenSize ? 'd-lg-none' : 'd-none d-lg-flex')}>
                <div className="nav-item dropdown mx-3 text-light">
                    <a className="button nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="h3 bi bi-translate"></span>
                    </a>
                    <ul className="dropdown-menu-end dropdown-menu dropdown-menu-dark">
                        <>
                            {
                                LANGUAGES.map((language, index) =>
                                    <li key={index}><a className="dropdown-item" onClick={() => changeLanguage(language[0])}>{language[1]}</a></li>
                                )
                            }
                        </>
                    </ul>
                </div>

                {auth != null && <div className="nav-item dropdown mx-3 text-light">
                    <a className="button nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="h3 bi bi-person-circle"></span>
                    </a>
                    <ul className="dropdown-menu-end dropdown-menu dropdown-menu-dark">

                        <li>
                            <NavLink className="dropdown-item" to={"/" + languageCode + "/account/details"}>
                                {t('header_my_account')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="dropdown-item" to={"/" + languageCode + "/account/enrollments"}>
                                {t('header_my_classes')}
                            </NavLink>
                        </li>
                        <li><a className="dropdown-item" onClick={logout_option}>{t('header_logout')}</a></li>

                    </ul>
                </div>}

                <button className="navbar-toggler mx-2" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        )
    }

    return (
        <>
            <nav id="top" className="navbar navbar-expand-lg bg-primary navbar-dark py-3">
                <div className="container-fluid  mx-0 mx-md-4 ">

                    <NavLink to={"/" + languageCode} className="navbar-brand title mx-0 py-0 d-block d-lg-none">
                        {t('app_title')}
                    </NavLink>

                    <NavLink to={"/" + languageCode} className="navbar-brand fs-4 py-0 d-none d-lg-block">
                        {t('app_title')}
                    </NavLink>

                    {generateLanguageButton(true)}

                    <div className="collapse navbar-collapse border-top mt-2 me-3 mt-lg-0 border-4 border-lg-top-0" id="navmenu">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to={"/" + languageCode + "/courses"} className="nav-link fs-5 pb-1">
                                    {t('header_courses')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/" + languageCode + "/instructors"} className="nav-link fs-5 pb-1">
                                    {t('header_instructors')}
                                </NavLink>
                            </li>
                        </ul>
                        {auth == null && <div className="navbar-nav ms-auto">
                            <div className="d-flex align-items-start">
                                <li className="nav-item mr-1">
                                    <NavLink to={"/" + languageCode + "/login"} className="nav-link fs-6 pb-0">
                                        {t('header_login')}
                                    </NavLink>
                                </li>
                                <li className="nav-item mx-4 mx-lg-2 nav-link pb-0">
                                    |
                                </li>
                                <li className="nav-item mr-1">
                                    <NavLink to={"/" + languageCode + "/signup"} className="nav-link fs-6 pb-0">
                                        {t('header_signup')}
                                    </NavLink>
                                </li>
                            </div>
                        </div>}
                    </div>

                    {generateLanguageButton(false)}

                </div>
            </nav>
        </>
    )
}

export default Navbar;